<template>
  <v-navigation-drawer v-model="drawer" temporary scrim="true" location="right" class="pt-4 px-3" color="#111213"
    mobile-breakpoint="xs" :width="$vuetify.display.xs ? $vuetify.display.width : drawer ? '400px' : ''">
    <v-list nav width="100%" size="compact">
      <v-list-item class="mb-15 pb-0" role="button"  aria-label="logo-and-close-btn">
        <svg width="57" height="31" viewBox="0 0 57 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M8.82389 22.1866C8.002 21.7176 8.06775 22.0824 7.17353 20.8643C3.62953 16.07 16.5694 8.2988 20.7578 0.208441C15.7015 0.0195353 10.4282 -0.664432 7.77187 2.1952C4.30019 5.94074 -0.959927 11.1389 0.151273 15.3795C0.716736 17.5486 11.4474 29.8535 13.0254 30.9935C16.2735 28.2967 18.8707 23.2158 22.0202 20.988C25.3735 24.0887 27.6354 27.8603 31.015 31L57 0.130273C48.5707 0.091189 46.322 -0.924991 42.2256 4.24059L26.8135 22.1996C22.0071 17.9916 26.6031 15.7964 28.8518 11.9206H18.417C16.3196 12.37 11.9668 19.5615 8.82389 22.1866Z"
            fill="#C8C8F3" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M29.2134 9.62766C32.5667 9.62766 32.8626 7.58227 34.6116 5.61505C35.7885 4.29271 38.1622 1.73923 38.8723 0.156335C28.9438 9.70658e-09 26.8332 -1.13995 19.8964 9.52995L29.2134 9.63417V9.62766Z"
            fill="#C8C8F3" />
        </svg>

        <template v-slot:append>
          <v-btn icon :class="drawer ? 'btn-close-drawer' : ''" color="#D91C49" size="x-large"
            v-if="$vuetify.display.smAndDown" @click.stop="drawer = !drawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-list-item>


      <v-list-item href="/#" value="home" class="drawer-list-items" role="button">
        Intro
      </v-list-item>
      <v-list-item href="/#partners" value="real_estate" class="drawer-list-items" role="button">
        Partners
      </v-list-item>
      <v-list-item href="/#about" value="about" class="drawer-list-items" role="button">
        About Us
      </v-list-item>
      <v-list-item href="/#cpm" value="learn" class="drawer-list-items" role="button">
        Services
      </v-list-item>
      <v-list-item href="/#process" value="partners" class="drawer-list-items" role="button">
        Our Process
      </v-list-item>
      <v-list-item href="/#GetInTouch" value="contact" class="drawer-list-items" role="button">
        Get In Touch
      </v-list-item>



      <v-row justify="space-between" class="ma-0 mt-15 pt-15 px-2">
        <v-btn variant="text" class="drawer-btn-links px-2" href="" target="_blank">
          INSTAGRAM
        </v-btn>
        <v-btn variant="text" class="drawer-btn-links px-2" href="" target="_blank">
          FaCEbook
        </v-btn>
        <v-btn variant="text" class="drawer-btn-links px-2" href="" target="_blank">
          Whatsapp
        </v-btn>
        <v-btn variant="text" class="drawer-btn-links px-2" href="" target="_blank">
          Telegram
        </v-btn>
      </v-row>
    </v-list>
  </v-navigation-drawer>

  <v-app-bar class="appBarHeader" :elevation="0" scroll-behavior="elevate" extended height="120px" density="compact"
    extension-height="0px">
    <v-container class="pt-4 pt-sm-10 px-2" fluid>
      <v-row justify="center">
        <v-col cols="11" sm="11" align-self="center">
          <v-row justify="space-between">

            <v-col cols="5" sm="4" md="3" align-self="center">
              <a href="/#">
                <v-img min-width="100px" max-width="180px" alt="work-logo" class="img-logo-big"
                  src="../assets/img/logo.png"></v-img>
              </a>
            </v-col>

            <v-col offset="0" cols="auto" sm="auto" md="7" lg="6" xl="6" align-self="center">

              <div class="appbarBtns py-1 px-1 px-lg-3">
                <v-btn href="/#" rounded="lg" text class="px-2 px-lg-3" v-if="$vuetify.display.mdAndUp"
                  :class="$route.hash == '' && $route.name == 'home' ? 'btn-appbar-active' : 'btn-appbar'">
                  Intro
                </v-btn>
                <v-btn href="/#partners" rounded="lg" text class="px-2 px-lg-3" v-if="$vuetify.display.mdAndUp"
                  :class="$route.hash == '#partners' || $route.name == 'property_gallary' ? 'btn-appbar-active' : 'btn-appbar'">
                  Partners
                </v-btn>
                <v-btn href="/#about" rounded="lg" text class="px-2 px-lg-3" v-if="$vuetify.display.mdAndUp"
                  :class="$route.hash == '#about' ? 'btn-appbar-active' : 'btn-appbar'">
                  About Us
                </v-btn>
                <v-btn href="/#cpm" rounded="lg" text class="px-2 px-lg-3" v-if="$vuetify.display.mdAndUp"
                  :class="$route.hash == '#cpm' ? 'btn-appbar-active' : 'btn-appbar'">
                  Services
                </v-btn>
                <v-btn href="/#process" rounded="lg" text class="px-2 px-lg-3" v-if="$vuetify.display.mdAndUp"
                  :class="$route.hash == '#process' ? 'btn-appbar-active' : 'btn-appbar'">
                  Our Process
                </v-btn>
                <v-btn href="/#GetInTouch" rounded="lg" text class="px-2 px-lg-3" v-if="$vuetify.display.mdAndUp"
                  :class="$route.hash == '#GetInTouch' ? 'btn-appbar-active' : 'btn-appbar'">
                  Get In Touch
                </v-btn>

                <v-btn rounded="lg" icon class="btn-appbar px-3" v-if="$vuetify.display.smAndDown"
                  @click.stop="drawer = !drawer">
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </div>

            </v-col>


            <v-col v-if="$vuetify.display.mdAndUp" offset="0" offset-lg="1" cols="auto" sm="auto" md="2"
              align-self="center">
              <div class="appbarBtns py-1 pl-2 pr-3" style="width: fit-content;">
                <v-btn href="https://calendly.com/office-work-o/15min" target="_blank" rounded="lg" text class="px-3"
                  :class="$route.name == 'contact' ? 'btn-appbar-active' : 'btn-appbar'">
                  <svg width="7" height="7" class="mr-1" viewBox="0 0 7 7" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.27692 0.145279C3.36817 -0.0484267 3.63183 -0.0484261 3.72308 0.145279L4.61192 2.03203C4.64816 2.10895 4.7182 2.16226 4.79923 2.1746L6.78673 2.47715C6.99078 2.50822 7.07225 2.77091 6.9246 2.92169L5.48643 4.39032C5.4278 4.4502 5.40105 4.53646 5.41489 4.62101L5.75439 6.69474C5.78925 6.90765 5.57594 7.07 5.39344 6.96948L3.61576 5.9904C3.54329 5.95048 3.45671 5.95048 3.38424 5.9904L1.60656 6.96948C1.42406 7.07 1.21075 6.90765 1.24561 6.69474L1.58511 4.62101C1.59895 4.53646 1.5722 4.4502 1.51357 4.39032L0.0753983 2.92169C-0.0722528 2.77091 0.00922334 2.50822 0.213272 2.47715L2.20077 2.1746C2.2818 2.16226 2.35184 2.10895 2.38808 2.03203L3.27692 0.145279Z"
                      fill="#D91C49" />
                  </svg>
                  Book a call
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  name: "HeaderComponent",
  data: () => ({
    drawer: null,
  }),
  methods: {
  },
};
</script>
<style scoped>
.drawer-btn-links {
  color: #D91C49;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.005rem;
}

.drawer-item-consult {
  background: rgba(47, 47, 47, 0.43);
  font-size: 20px;
  color: #9a9a9b;
  text-transform: inherit;
  border-radius: 9px;
  font-weight: 400;
  letter-spacing: -0.01rem;
}

.drawer-list-items {
  color: #C8C8F3;
  font-size: 2.25rem !important;
  line-height: 54px;
  font-weight: 400;
  letter-spacing: -0.01rem;
}

.drawer-langs {
  min-width: 50px;
  background: rgba(47, 47, 47, 0.43);
  border-radius: 9px;
  font-size: 19px;
  font-weight: 400;
  letter-spacing: -0.008rem;
}

.subheader {
  color: #373737;
  font-size: 12px;
  text-transform: uppercase;
  min-height: 25px;
}

/* .drawer-class {
  animation-name: drawer_anim;
  animation-duration: 1s;
  animation-direction: alternate;
  transform: translateX(0%) matrix(1, 0, 0, 1, 0, 0) skew(0deg, 0deg);
}
@keyframes drawer_anim {
  from {
    transform: translateX(100%) matrix(1, 0, -0.2, 1.2, 0, 0) skew(24deg, 0deg);
  }
  to {
    transform: translateX(0%) matrix(1, 0, 0, 1, 0, 0) skew(0deg, 0deg);
  }
} */
.btn-close-drawer {
  /* animation-name: Btn_close_drawer_anim;
  animation-duration: 1.1s;
  animation-direction: normal;
  animation-timing-function: linear;
  animation-fill-mode: forwards; */
  /* animation: Btn_close_drawer_anim 1.1s ease-in-out 0s normal forwards;
  -o-animation: Btn_close_drawer_anim 1.1s ease-in-out 0s normal forwards;
  -ms-animation: Btn_close_drawer_anim 1.1s ease-in-out 0s normal forwards;
  -moz-animation: Btn_close_drawer_anim 1.1s ease-in-out 0s normal forwards;
  -webkit-animation: Btn_close_drawer_anim 1.1s ease-in-out 0s normal forwards;
  transform: rotate(-360deg) scale(0.4); */
}

/* @keyframes Btn_close_drawer_anim {
  from {
    transform: rotate(-360deg) scale(0.4);
  }

  to {
    transform: rotate(0deg) scale(1);
  }
} */

.block-right {
  max-width: 50%;
}

.btn-consult {
  background: #2f2f2f54;
  border-radius: 9px;
}

.list-lang {
  background: #1717179f !important;
  border-radius: 15px !important;
  color: #f6f2e6;
  font: 15px;
  backdrop-filter: blur(7px);
}

.btn-lang {
  min-width: 45px;
  background: #2f2f2f54;
  padding: 2px !important;
  border-radius: 9px;
}

.appBarHeader {
  max-height: 120px;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
}

.appbarBtns {
  background: #1717179f;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  backdrop-filter: blur(7px);
  align-items: center;
}

.btn-appbar {
  text-transform: inherit;
  color: #9a9a9b;
  font-weight: 400;
  letter-spacing: 0px;
  /*font-size: 15px;*/
  font-size: 1rem;
}

.btn-appbar-active {
  text-transform: inherit;
  color: #9a9a9b;
  background-color: rgba(47, 47, 47, 0.43);
  font-weight: 400;
  letter-spacing: 0px;
  border-radius: 9px;
  /*font-size: 15px;*/
  font-size: 1rem;
}

.img-logo {
  margin-right: auto;
  transition: .3s;
}

.img-logo:hover {
  cursor: pointer;
  filter: brightness(4);
}

.img-logo-big {
  transition: .3s;
}

.img-logo-big:hover {
  filter: brightness(2);
  cursor: pointer;
}


@media screen and (max-width: 600px) {
  .appBarHeader {
    background: linear-gradient(rgba(17, 18, 19, 1), rgba(0, 0, 0, 0));
  }
}
</style>