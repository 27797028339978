<template>
    <v-container :fluid="$vuetify.display.mdAndDown ? true : false" id="Legal-content">
        <v-row justify="center">
            <v-col cols="12" sm="11" md="11">
                <v-row justify="center">
                    <v-col cols="12">
                        <v-fade-transition>
                            <v-card class="cards-content pa-4" flat v-show="choosePage == 'allgemeine'">
                                <div class="cards-content-text">
                                    <h2>Terms and Conditions / Allgemeine Geschäftsbedingungen</h2>
                                    <p>Address: Austria, Canettistraße 1/602, 1100, Wien<br>
                                        Company Number: AT 1023600040138<br>
                                        Email: <a href="mailto:office@work-o.com">office@work-o.com</a></p>

                                    <h2>Interpretation and Definitions</h2>
                                    <h3>Interpretation</h3>
                                    <p>The words with their initial letter capitalized have meanings defined under the
                                        following conditions. The following definitions shall have the same meaning
                                        regardless of whether they appear in singular or plural form.</p>

                                    <h3>Definitions</h3>
                                    <p>For the purposes of these Terms and Conditions:</p>
                                    <ul>
                                        <li><strong>Affiliate</strong> refers to an entity that controls, is controlled
                                            by, or is under common control with a party, where "control" means ownership
                                            of 50% or more of the shares, equity interest, or other securities entitled
                                            to vote for election of directors or other managing authority.</li>
                                        <li><strong>Country</strong> refers to Austria.</li>
                                        <li><strong>Company</strong> (referred to as either "the Company", "We", "Us",
                                            or "Our" in this Agreement) refers to WORK-O PRODUCTION OG, Canettistraße
                                            1/602, 1100, Wien, Austria.</li>
                                        <li><strong>Device</strong> means any device that can access the Service, such
                                            as a computer, cellphone, or digital tablet.</li>
                                        <li><strong>Service</strong> refers to the Website.</li>
                                        <li><strong>Terms and Conditions</strong> (also referred to as "Terms") mean
                                            these Terms and Conditions that form the entire agreement between You and
                                            the Company regarding the use of the Service.</li>
                                        <li><strong>Third-party Social Media Service</strong> means any services or
                                            content (including data, information, products, or services) provided by a
                                            third party that may be displayed, included, or made available by the
                                            Service.</li>
                                        <li><strong>Website</strong> refers to work-o.com, accessible from <a
                                                href="https://www.work-o.com/"
                                                target="_blank">https://www.work-o.com/</a></li>
                                        <li><strong>You</strong> means the individual accessing or using the Service, or
                                            the company, or other legal entity on behalf of which such individual is
                                            accessing or using the Service, as applicable.</li>
                                    </ul>

                                    <h2>Acknowledgment</h2>
                                    <p>These Terms and Conditions govern the use of this Service and form the agreement
                                        between You and the Company. They define the rights and obligations of all users
                                        regarding the use of the Service.</p>
                                    <p>Your access to and use of the Service is conditioned upon Your acceptance of and
                                        compliance with these Terms and Conditions. These Terms apply to all visitors,
                                        users, and others who access or use the Service.</p>
                                    <p>By accessing or using the Service, You agree to be bound by these Terms. If You
                                        disagree with any part of these Terms and Conditions, You may not access the
                                        Service.</p>
                                    <p>You represent that You are over the age of 18. The Company does not permit those
                                        under 18 to use the Service.</p>
                                    <p>Your access to and use of the Service is also conditioned on Your acceptance of
                                        and compliance with the Company’s Privacy Policy. The Privacy Policy outlines
                                        how We collect, use, and disclose Your personal information when You use the
                                        Service. Please read Our Privacy Policy carefully before using Our Service.</p>

                                    <h2>Links to Other Websites</h2>
                                    <p>Our Service may contain links to third-party websites or services that are not
                                        owned or controlled by the Company.</p>
                                    <p>The Company assumes no responsibility for the content, privacy policies, or
                                        practices of any third-party websites or services. You further acknowledge and
                                        agree that the Company shall not be responsible or liable for any damage or loss
                                        caused by or in connection with the use of or reliance on any such content,
                                        goods, or services available on or through any such websites or services.</p>
                                    <p>We strongly advise You to read the terms and conditions and privacy policies of
                                        any third-party websites or services that You visit.</p>

                                    <h2>Termination</h2>
                                    <p>We may terminate or suspend Your access to the Service immediately, without prior
                                        notice or liability, for any reason, including without limitation if You breach
                                        these Terms and Conditions.</p>
                                    <p>Upon termination, Your right to use the Service will cease immediately.</p>

                                    <h2>Limitation of Liability</h2>
                                    <p>Notwithstanding any damages that You might incur, the entire liability of the
                                        Company and any of its suppliers under any provision of these Terms shall be
                                        limited to the amount actually paid by You through the Service, or 100 USD if
                                        You haven't purchased anything through the Service.</p>
                                    <p>To the maximum extent permitted by applicable law, in no event shall the Company
                                        or its suppliers be liable for any special, incidental, indirect, or
                                        consequential damages whatsoever, including loss of profits, loss of data, or
                                        business interruption, arising out of or related to the use or inability to use
                                        the Service.</p>

                                    <h2>"AS IS" and "AS AVAILABLE" Disclaimer</h2>
                                    <p>The Service is provided to You on an "AS IS" and "AS AVAILABLE" basis without any
                                        warranty of any kind. The Company, on its own behalf and on behalf of its
                                        Affiliates, disclaims all warranties, express or implied, including but not
                                        limited to merchantability, fitness for a particular purpose, and
                                        non-infringement. The Company does not guarantee that the Service will be
                                        uninterrupted, error-free, or free of viruses or other harmful components.</p>
                                    <p>Some jurisdictions do not allow the exclusion of certain types of warranties or
                                        limitations on consumer rights, so some of these exclusions may not apply to
                                        You.</p>

                                    <h2>Governing Law</h2>
                                    <p>These Terms shall be governed by the laws of Austria, excluding its conflict of
                                        law rules.</p>

                                    <h2>Disputes Resolution</h2>
                                    <p>If You have any concerns or disputes about the Service, You agree to first try to
                                        resolve the dispute informally by contacting the Company.</p>

                                    <h2>Severability and Waiver</h2>
                                    <h3>Severability</h3>
                                    <p>If any provision of these Terms is held to be unenforceable or invalid, it will
                                        be modified to the extent necessary to make it enforceable, and the remaining
                                        provisions will continue in full force and effect.</p>

                                    <h3>Waiver</h3>
                                    <p>The failure to enforce any right or provision of these Terms will not be
                                        considered a waiver of those rights.</p>

                                    <h2>Changes to These Terms and Conditions</h2>
                                    <p>We reserve the right to modify or replace these Terms at Our sole discretion. We
                                        will provide at least 30 days' notice before any significant changes take
                                        effect. By continuing to use the Service after such changes, You agree to be
                                        bound by the new terms. If You do not agree, please discontinue using the
                                        Service.</p>

                                    <h2>Contact Us</h2>
                                    <p>If you have any questions about these Terms and Conditions, You can contact
                                        us:<br>
                                        By email: <a href="mailto:office@work-o.com">office@work-o.com</a><br>
                                        By visiting our website: <a href="https://www.work-o.com/"
                                            target="_blank">https://www.work-o.com/</a></p>

                                </div>
                            </v-card>
                        </v-fade-transition>
                        <v-fade-transition>
                            <v-card class="cards-content pa-4" flat v-show="choosePage == 'datenschutz'">
                                <div class="cards-content-text">

                                    <h2>Privacy Policy / Datenschutz</h2>
                                    <p>Last updated: September 22, 2024</p>

                                    <p>This Privacy Policy describes the policies and procedures of WORK-O PRODUCTION OG
                                        on the collection, use, and disclosure of Your information when You use our
                                        Service. It also informs You about Your privacy rights and how the law protects
                                        You.</p>

                                    <p>We use Your personal data to provide and improve our Service. By using the
                                        Service, You agree to the collection and use of information as outlined in this
                                        Privacy Policy.</p>

                                    <h3>Interpretation and Definitions</h3>

                                    <h4>Interpretation</h4>
                                    <p>Words in which the initial letter is capitalized have meanings defined under the
                                        following conditions. These definitions shall have the same meaning regardless
                                        of whether they appear in singular or plural form.</p>

                                    <h4>Definitions</h4>
                                    <ul>
                                        <li><strong>Account</strong> means a unique account created for You to access
                                            our Service or parts of our Service.</li>
                                        <li><strong>Affiliate</strong> means an entity that controls, is controlled by,
                                            or is under common control with a party, where "control" means ownership of
                                            50% or more of the shares or voting securities.</li>
                                        <li><strong>Company</strong> (referred to as either "the Company", "We", "Us",
                                            or "Our" in this Agreement) refers to WORK-O PRODUCTION OG, located at
                                            Canettistraße 1/602, 1100, Wien, Austria.</li>
                                        <li><strong>Cookies</strong> are small files placed on Your device by a website,
                                            containing details of Your browsing history on that website, among other
                                            uses.</li>
                                        <li><strong>Country</strong> refers to: Austria.</li>
                                        <li><strong>Device</strong> means any device that can access the Service such as
                                            a computer, cellphone, or digital tablet.</li>
                                        <li><strong>Personal Data</strong> is any information that relates to an
                                            identified or identifiable individual.</li>
                                        <li><strong>Service</strong> refers to the website operated by the Company.</li>
                                        <li><strong>Service Provider</strong> refers to any natural or legal person who
                                            processes data on behalf of the Company.</li>
                                        <li><strong>Usage Data</strong> refers to data collected automatically, either
                                            generated by the use of the Service or from the Service infrastructure
                                            itself (e.g., the duration of a page visit).</li>
                                        <li><strong>Website</strong> refers to the WORK-O PRODUCTION OG website,
                                            accessible from <a href="https://work-o.com">https://work-o.com</a>.</li>
                                        <li><strong>You</strong> means the individual accessing or using the Service or
                                            the company or other legal entity on behalf of which such individual is
                                            accessing or using the Service.</li>
                                    </ul>

                                    <h3>Collecting and Using Your Personal Data</h3>

                                    <h4>Types of Data Collected</h4>
                                    <h5>Personal Data</h5>
                                    <p>While using Our Service, We may ask You to provide Us with certain personally
                                        identifiable information that can be used to contact or identify You. This may
                                        include, but is not limited to:</p>
                                    <ul>
                                        <li>Email address</li>
                                        <li>Usage Data</li>
                                    </ul>

                                    <h5>Usage Data</h5>
                                    <p>Usage Data is collected automatically when using the Service. This data may
                                        include information such as Your device’s IP address, browser type, browser
                                        version, the pages You visit on our Service, the time and date of Your visit,
                                        the time spent on those pages, and other diagnostic data.</p>

                                    <p>When accessing the Service via a mobile device, We may collect additional
                                        information such as the type of mobile device, unique device ID, and operating
                                        system.</p>

                                    <h3>Tracking Technologies and Cookies</h3>
                                    <p>We use Cookies and similar tracking technologies to monitor activity on Our
                                        Service and store certain information. Tracking technologies used include
                                        beacons, tags, and scripts to collect and track information and to improve and
                                        analyze Our Service.</p>

                                    <p>Cookies can be "persistent" or "session" Cookies. Persistent Cookies remain on
                                        Your device when You go offline, while session Cookies are deleted once You
                                        close Your browser.</p>

                                    <p>For detailed information on how we use Cookies, please review our Cookies Policy.
                                    </p>

                                    <h3>Use of Your Personal Data</h3>
                                    <p>The Company may use Personal Data for the following purposes:</p>
                                    <ul>
                                        <li>To provide and maintain our Service</li>
                                        <li>To manage Your Account as a registered user of the Service.</li>
                                        <li>For the performance of a contract for products or services You have
                                            purchased.</li>
                                        <li>To contact You with information regarding updates, offers, and other
                                            communications.</li>
                                        <li>To manage Your requests and inquiries.</li>
                                    </ul>

                                    <h3>Retention of Your Personal Data</h3>
                                    <p>The Company will retain Your Personal Data only as long as necessary for the
                                        purposes set out in this Privacy Policy. We will retain and use Your Personal
                                        Data to comply with legal obligations, resolve disputes, and enforce agreements
                                        and policies.</p>

                                    <h3>Transfer of Your Personal Data</h3>
                                    <p>Your information, including Personal Data, may be processed at our operating
                                        offices and other locations where involved parties are located. This information
                                        may be transferred to computers outside of Your country where the data
                                        protection laws may differ from Your jurisdiction. Your consent to this Privacy
                                        Policy represents Your agreement to such transfer.</p>

                                    <h3>Delete Your Personal Data</h3>
                                    <p>You have the right to request the deletion of Your Personal Data. If You wish to
                                        delete or update Your data, You may contact us at <a
                                            href="mailto:office@work-o.com">office@work-o.com</a>. Certain data may be
                                        retained when required by law.</p>

                                    <h3>Disclosure of Your Personal Data</h3>
                                    <p>We may disclose Your personal information under specific circumstances,
                                        including:</p>
                                    <ul>
                                        <li>Business Transactions: If the Company is involved in a merger, acquisition,
                                            or asset sale.</li>
                                        <li>Law Enforcement: If required by law or public authorities.</li>
                                        <li>Legal Requirements: To comply with legal obligations and protect Company
                                            rights.</li>
                                    </ul>

                                    <h3>Security of Your Personal Data</h3>
                                    <p>We use commercially acceptable means to protect Your Personal Data. However, no
                                        transmission over the internet or method of electronic storage is 100% secure,
                                        and We cannot guarantee its absolute security.</p>

                                    <h3>Links to Other Websites</h3>
                                    <p>Our Service may contain links to external websites. We are not responsible for
                                        the content or privacy policies of those third-party websites.</p>

                                    <h3>Changes to this Privacy Policy</h3>
                                    <p>We may update this Privacy Policy from time to time. You will be notified of any
                                        changes via email and/or a prominent notice on Our Service. The "Last updated"
                                        date at the top of this Privacy Policy will be revised accordingly.</p>

                                    <h3>Contact Us</h3>
                                    <p>If you have any questions about this Privacy Policy, You can contact us:</p>
                                    <ul>
                                        <li>By email: <a href="mailto:office@work-o.com">office@work-o.com</a></li>
                                    </ul>
                                </div>
                            </v-card>
                        </v-fade-transition>
                        <v-fade-transition>
                            <v-card class="cards-content  pa-4" flat v-show="choosePage == 'cookie'">
                                <div class="cards-content-text">
                                    <h1>Cookies Policy</h1>
                                    <h2>Introduction</h2>
                                    <p>WORK-O PRODUCTION OG ("we," "us," or "our") operates the website <a
                                            href="https://work-o.com">work-o.com</a> (the "Site"). This Cookies Policy
                                        explains how we use cookies and similar technologies to recognize you when you
                                        visit our Site. It outlines what these technologies are, why we use them, and
                                        your rights to control their use.</p>

                                    <h2>What are cookies?</h2>
                                    <p>Cookies are small data files stored on your computer or mobile device when you
                                        visit a website. They are widely used by website owners to make websites
                                        function correctly, improve performance, and provide reporting information.</p>

                                    <h2>Why do we use cookies?</h2>
                                    <p>We use cookies for various reasons, including ensuring our Site operates
                                        efficiently, enhancing user experience, and collecting data for analytics. Some
                                        cookies are "essential" or "strictly necessary" for the Site's functionality,
                                        while others help us understand user behavior to improve our services. Third
                                        parties may also use cookies for analytics and other purposes.</p>

                                    <h2>The cookies we use</h2>
                                    <p>Our Site uses cookies, including those provided by third-party services like
                                        Google Analytics 4, to collect and analyze information about user interactions.
                                        This data is anonymized and includes details such as the pages you visit, how
                                        long you stay, and how you navigate the Site. These insights help us improve the
                                        Site and your experience.</p>

                                    <h2>Your choices regarding cookies</h2>
                                    <p>You have the right to decide whether to accept or reject cookies. When you visit
                                        our Site, you can manage your preferences through the cookie consent banner.
                                        Additionally, you can adjust your browser settings to block cookies, though this
                                        may limit access to certain features or functionality of the Site.</p>

                                    <h2>GDPR Compliance</h2>
                                    <p>As we serve users in the European Union, we comply with the General Data
                                        Protection Regulation (GDPR). This means we will only collect and process your
                                        personal data with your explicit consent. You also have the right to withdraw
                                        consent at any time and may request access to, correction of, or deletion of
                                        your personal data.</p>

                                    <h2>Company Information</h2>
                                    <p>The company responsible for managing cookies and processing data through this
                                        Site is:</p>
                                    <p><strong>WORK-O PRODUCTION OG</strong><br>
                                        Austria, Canettistraße 1/602, 1100, Wien<br>
                                        Company number: AT 1023600040138<br>
                                        Email: <a href="mailto:office@work-o.com">office@work-o.com</a></p>

                                    <h2>Changes to this Cookies Policy</h2>
                                    <p>We may update this Cookies Policy from time to time to reflect changes in our
                                        practices or legal requirements. Please check this page regularly to stay
                                        informed about how we use cookies and similar technologies.</p>

                                    <h2>Contact Information</h2>
                                    <p>If you have any questions about our use of cookies or this Cookies Policy, you
                                        can contact us at:<br>
                                        Email: <a href="mailto:office@work-o.com">office@work-o.com</a></p>
                                </div>
                            </v-card>
                        </v-fade-transition>
                        <v-fade-transition>
                            <v-card class="cards-content  pa-4" flat v-show="choosePage == 'impressum'">
                                <div class="cards-content-text">
                                    <h1>Impressum</h1>
                                    <p><strong>Angaben gemäß § 5 TMG:</strong></p>
                                    <p>
                                        WORK-O PRODUCTION OG<br>
                                        Canettistraße 1/602<br>
                                        1100 Wien<br>
                                        Austria<br>
                                        Unternehmensnummer: AT 1023600040138<br>
                                        E-Mail: <a href="mailto:office@work-o.com">office@work-o.com</a><br>
                                        Website: <a href="http://www.work-o.com" target="_blank">www.work-o.com</a>
                                    </p>
                                    <p>
                                        Vertretungsberechtigte Gesellschafter:<br>
                                    </p>
                                    <h2>Haftungsausschluss (Disclaimer):</h2>
                                    <p>
                                        Die Inhalte dieser Website wurden mit größter Sorgfalt erstellt. Für die
                                        Richtigkeit,
                                        Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr
                                        übernehmen.
                                        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
                                        Seiten
                                        nach den allgemeinen Gesetzen verantwortlich.
                                    </p>
                                    <h2>Urheberrecht:</h2>
                                    <p>
                                        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
                                        unterliegen
                                        dem österreichischen Urheberrecht. Die Vervielfältigung, Bearbeitung,
                                        Verbreitung und
                                        jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                                        schriftlichen
                                        Zustimmung des jeweiligen Autors bzw. Erstellers.
                                    </p>
                                </div>
                            </v-card>
                        </v-fade-transition>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { useLocale } from "vuetify";

export default {
    name: "LegalContent",
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
    computed: {
        choosePage() {
            return this.$route.params.name ?? "terms"
        }
    }
};
</script>

<style>
#Legal-content .cards-content ul {
    margin-left: 1rem;
}

#Legal-content .cards-content a {
    color: #ffffff;
}

#Legal-content .cards-content h1 {
    font-size: 2.5rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

#Legal-content .cards-content h3 {
    font-size: 1.4rem;
    margin-top: 0.6rem;
    margin-bottom: 0.5rem;
}

#Legal-content .cards-content h2 {
    font-size: 1.8rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

#Legal-content .cards-content h5 {
    font-size: 1.3rem;
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
}

#Legal-content .cards-content h5 {
    font-size: 1.2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
}

#Legal-content .cards-content p {
    font-size: 1.05rem;
    margin-top: 1.1rem;
    margin-bottom: 1.1rem;
}
</style>
<style scoped>
.cards-content-text {
    color: #E6E8EB;
}

.cards-content {
    background: transparent;
}


.list {
    background: transparent;
}

.list-item {
    background: transparent;
}

.text-title {
    color: #9A9A9B;
    font-size: 1.6rem;
    font-weight: 400;
}

.btn-allp {
    text-transform: inherit;
    letter-spacing: -0.02rem;
    font-weight: 400;
    font-size: 1.2rem;
}

.container-main {
    padding-top: 15vh;
}

.row-line {
    width: 100%;
}

.title-small2 {
    color: #c4c4c4;
    font-weight: 400;
    letter-spacing: -0.035rem !important;
}

.title-small {
    color: #c4c4c4;
    font-weight: 400;
    letter-spacing: -0.04rem !important;
    text-shadow: 8px 13px 35px rgba(0, 0, 0, 0.5);
}

.mh-90 {
    min-height: 69px;
}

.title-big {
    letter-spacing: -0.04rem !important;
    color: #f6f2e6;
    text-shadow: rgba(0, 0, 0, 0.87) 0px 4px 30px;
    font-size: 6rem;
    font-weight: 400;
    line-height: 6rem;
}

.divider-partner {
    border-width: 1px;
    opacity: 1;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, rgba(246, 242, 230, 0.36), rgba(255, 255, 255, 0));
}

@media screen and (max-width: 600px) {
    .title-big {
        font-size: 3rem;
        line-height: 3rem;
    }

    .title-small,
    .title-small2 {
        font-size: 0.85rem !important;
        line-height: 1.1rem !important;
    }

    .row-line {
        height: unset !important;
    }

    .btn-allp {
        font-size: 1rem;
    }

    .mh-90 {
        min-height: 55px;
    }

}

@media screen and (min-width: 600px) and (max-width: 960px) {
    .title-big {
        zoom: 0.8;
    }

    .title-small,
    .title-small2 {
        zoom: 0.8;
    }
}
</style>