<template>
    <v-container fluid class="container-main">
        <v-container fluid class="pa-0">
            <v-row justify="center">
                <v-col cols="12" md="11">
                    <v-row justify="space-between">
                        <v-col cols="10" sm="10" align-self="center">
                            <h2 class="text-title">
                                Legal Page
                            </h2>
                        </v-col>
                    </v-row>

                    <v-row justify="space-between" class="my-10">
                        <v-col cols="12">
                            <v-list class="list" v-model:selected="choosePage">
                                <v-list-item value="allgemeine" color="#fff" rounded="shaped" class="list-item"
                                    @click="ChangeParams('allgemeine')">
                                    <v-list-item-title class="list-item-title">
                                        Allgemeine Geschäftsbedingungen
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item value="datenschutz" color="#fff" rounded="shaped" class="list-item"
                                    @click="ChangeParams('datenschutz')">
                                    <v-list-item-title class="list-item-title">
                                        Datenschutz
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item value="cookie" color="#fff" rounded="shaped" class="list-item"
                                    @click="ChangeParams('cookie')">
                                    <v-list-item-title class="list-item-title">
                                        Cookie
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item value="impressum" color="#fff" rounded="shaped" class="list-item"
                                    @click="ChangeParams('impressum')">
                                    <v-list-item-title class="list-item-title">
                                        Impressum
                                    </v-list-item-title>
                                </v-list-item>

                            </v-list>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import { useLocale } from "vuetify";

export default {
    name: "LegalTop",
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
    data: () => ({
        choosePage: "terms",
    }),
    mounted() {
        this.choosePage = this.$route.params.name ? [this.$route.params.name] : ["terms"];
    },
    methods: {
        ChangeParams(name) {
            this.$router.push({ params: { name: name } });
            document.querySelector('#Legal-content').scrollIntoView({
                behavior: 'smooth'
            });
        }
    }
};
</script>

<style scoped>
.svg-center-absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.list-item-title {
    color: #9A9A9B;
    font-size: 1.6rem;
    font-weight: 400;
}

.list {
    background: transparent;
}

.list-item {
    background: transparent;
}

.container-main {
    min-width: 100vw;
    padding-top: 35vh;
    padding-bottom: 5vh;
    position: relative;
    z-index: 1;
    margin-top: -1px;
    border-radius: 0px 0px 25px 25px;
    box-shadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #FFFFFF1F;

    background: linear-gradient(180deg, #000000 0%, #14081A 42%, #09040C 95%);
}

.svg-animation {
    overflow: visible;
    transform-origin: 50% 50%;
    animation-name: svg-anim;
    animation-duration: 18s;
    animation-direction: normal;
    animation-timing-function: linear;
    transform: rotate(0deg);
    animation-iteration-count: infinite;
}

@keyframes svg-anim {
    from {
        transform: rotate(-360deg);
    }

    to {
        transform: rotate(0deg);
    }
}

.text-title {
    color: #E6E8EB;
    font-weight: 300;
    font-size: 5.4rem;
    line-height: 7rem;
    text-align: left;
    text-transform: uppercase;
}


@media (min-width: 600px) and (max-width: 960px) {
    .text-title {
        font-size: 3.8rem;
        font-weight: 300;
        line-height: 5rem;
    }

    .container-main {
        padding-top: 25vh;
    }

}

@media (max-width: 600px) {
    .text-title {
        font-size: 2.1rem;
        font-weight: 400;
        line-height: 3rem;
    }

    .container-main {
        padding-top: 25vh;
    }

    .options-description {
        font-size: .8rem;
    }

    .options-title {
        font-size: 2rem;
    }

}

.btn-allp {
    text-transform: inherit;
    letter-spacing: -0.02rem;
    font-weight: 400;
    font-size: 1.2rem;
}


.row-line {
    width: 100%;
}
</style>