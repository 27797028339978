<template>
    <v-container fluid class="container-main">
        <v-row justify="center" id="about">
            <v-col cols="12" sm="11" md="9">
                <v-card class="card-main px-3 px-sm-10 py-10 py-sm-15 animate-block">
                    <v-row justify="center">
                        <v-col cols="12" sm="8" md="6">
                            <h2 class="block-title">Leading marketing agency based in Austria</h2>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="12" class="px-0 px-sm-3">
                            <v-tabs id="tabs-marketing" v-model="tab" grow color="white" height="60"
                                slider-color="#D91C49" class="mt-6">
                                
                                <v-tab value="tab-1" color="#C8C8F3" base-color="#C8C8F3" class="tab-title">Industry
                                    Expertise</v-tab>
                                <v-tab value="tab-2" color="#C8C8F3" base-color="#C8C8F3" class="tab-title">
                                    <p v-if="$vuetify.display.smAndDown">Performance Optimization
                                    </p>
                                    <p v-if="$vuetify.display.mdAndUp">
                                        Performance-Driven Optimization
                                    </p>
                                </v-tab>
                                <v-tab value="tab-3" color="#C8C8F3" base-color="#C8C8F3" class="tab-title">
                                    <p v-if="$vuetify.display.smAndDown">
                                        Campaign Management
                                    </p>
                                    <p v-if="$vuetify.display.mdAndUp">
                                        Proactive
                                        Campaign Management
                                    </p>
                                </v-tab>
                               
                            </v-tabs>

                            <v-card-text>
                                <v-tabs-window v-model="tab">
                                    <v-tabs-window-item value="tab-1">
                                        <v-row justify="space-between">
                                            <v-col cols="12" sm="6" align-self="center" order="2" order-sm="1">
                                                <p class="tab-content-text">
                                                    We are deeply immersed in the rapidly growing iGaming industry. With
                                                    the
                                                    global market set to
                                                    surpass $100 billion, we help casinos and sportsbooks navigate this
                                                    competitive space with
                                                    precision.
                                                </p>
                                            </v-col>
                                            <v-col cols="12" sm="5" align-self="center" order="1" order-sm="2"
                                                style="position: relative;">
                                                <svg class="svg-logo-xs px-4" v-if="$vuetify.display.xs" width="100%"
                                                    viewBox="0 0 327 183" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M50.6213 130.728C45.9062 127.974 46.2834 130.116 41.1534 122.965C20.822 94.8175 95.0561 49.1932 119.084 1.69494C90.077 0.585882 59.8251 -3.42967 44.586 13.3592C24.6695 35.3491 -5.50695 65.8673 0.867829 90.7637C4.1118 103.499 65.6718 175.74 74.7247 182.433C93.3587 166.6 108.258 136.77 126.326 123.691C145.564 141.895 158.54 164.038 177.928 182.471L327 1.23602C278.642 1.00656 265.742 -4.9594 242.242 25.3676L153.825 130.804C126.251 106.099 152.618 93.2112 165.518 70.4565H105.656C93.6227 73.0952 68.6517 115.316 50.6213 130.728Z"
                                                        fill="url(#paint0_linear_210_546)" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M167.593 56.9948C186.83 56.9948 188.527 44.9864 198.561 33.4369C205.313 25.6736 218.93 10.6822 223.004 1.38903C166.046 0.471191 153.938 -6.2214 114.142 56.4212L167.593 57.0331V56.9948Z"
                                                        fill="url(#paint1_linear_210_546)" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear_210_546" x1="163.5"
                                                            y1="0.471191" x2="164.038" y2="143.122"
                                                            gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#C8C8F3" stop-opacity="0.15" />
                                                            <stop offset="1" stop-color="#C8C8F3" stop-opacity="0" />
                                                        </linearGradient>
                                                        <linearGradient id="paint1_linear_210_546" x1="163.5"
                                                            y1="0.471191" x2="164.038" y2="143.122"
                                                            gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#C8C8F3" stop-opacity="0.15" />
                                                            <stop offset="1" stop-color="#C8C8F3" stop-opacity="0" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>

                                                <svg width="100%" viewBox="0 0 290 290" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <g filter="url(#filter0_dii_335_148)">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M120 96C108.954 96 100 104.954 100 116V166C100 177.046 108.954 186 120 186H170C181.046 186 190 177.046 190 166V116C190 104.954 181.046 96 170 96H120ZM176.208 167.42C176.669 166.959 176.928 166.335 176.928 165.683C176.928 165.032 176.669 164.407 176.208 163.947C175.748 163.486 175.123 163.227 174.472 163.227H117.984V156.876L135.176 139.684L143.262 147.773C143.49 148.001 143.761 148.183 144.06 148.306C144.358 148.43 144.677 148.493 145 148.493C145.323 148.493 145.642 148.43 145.94 148.306C146.239 148.183 146.51 148.001 146.738 147.773L164.648 129.86V136.212C164.648 136.863 164.907 137.488 165.367 137.948C165.828 138.409 166.453 138.667 167.104 138.667C167.755 138.667 168.38 138.409 168.841 137.948C169.301 137.488 169.56 136.863 169.56 136.212V123.932C169.56 123.28 169.301 122.656 168.841 122.195C168.38 121.734 167.755 121.476 167.104 121.476H154.824C154.173 121.476 153.548 121.734 153.087 122.195C152.627 122.656 152.368 123.28 152.368 123.932C152.368 124.583 152.627 125.208 153.087 125.668C153.548 126.129 154.173 126.388 154.824 126.388H161.176L145 142.563L136.914 134.474C136.686 134.246 136.415 134.064 136.117 133.941C135.818 133.817 135.499 133.754 135.176 133.754C134.853 133.754 134.534 133.817 134.236 133.941C133.937 134.064 133.667 134.246 133.438 134.474L117.984 149.931V116.564C117.984 115.912 117.725 115.288 117.265 114.827C116.804 114.366 116.18 114.108 115.528 114.108C114.877 114.108 114.252 114.366 113.792 114.827C113.331 115.288 113.072 115.912 113.072 116.564V165.683C113.072 166.335 113.331 166.959 113.792 167.42C114.252 167.881 114.877 168.139 115.528 168.139H174.472C175.123 168.139 175.748 167.881 176.208 167.42Z"
                                                            fill="url(#paint0_linear_335_148)" />
                                                    </g>
                                                    <defs>
                                                        <filter id="filter0_dii_335_148" x="0" y="0" width="290"
                                                            height="290" filterUnits="userSpaceOnUse"
                                                            color-interpolation-filters="sRGB">
                                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                            <feColorMatrix in="SourceAlpha" type="matrix"
                                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                result="hardAlpha" />
                                                            <feOffset dy="4" />
                                                            <feGaussianBlur stdDeviation="50" />
                                                            <feComposite in2="hardAlpha" operator="out" />
                                                            <feColorMatrix type="matrix"
                                                                values="0 0 0 0 0.754411 0 0 0 0 0.224383 0 0 0 0 0.383392 0 0 0 1 0" />
                                                            <feBlend mode="normal" in2="BackgroundImageFix"
                                                                result="effect1_dropShadow_335_148" />
                                                            <feBlend mode="normal" in="SourceGraphic"
                                                                in2="effect1_dropShadow_335_148" result="shape" />
                                                            <feColorMatrix in="SourceAlpha" type="matrix"
                                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                result="hardAlpha" />
                                                            <feOffset dx="5" dy="-13" />
                                                            <feGaussianBlur stdDeviation="13.4" />
                                                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1"
                                                                k3="1" />
                                                            <feColorMatrix type="matrix"
                                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.67 0" />
                                                            <feBlend mode="normal" in2="shape"
                                                                result="effect2_innerShadow_335_148" />
                                                            <feColorMatrix in="SourceAlpha" type="matrix"
                                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                result="hardAlpha" />
                                                            <feOffset dx="-4" dy="6" />
                                                            <feGaussianBlur stdDeviation="6.55" />
                                                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1"
                                                                k3="1" />
                                                            <feColorMatrix type="matrix"
                                                                values="0 0 0 0 0.93068 0 0 0 0 0.446542 0 0 0 0 0.561813 0 0 0 1 0" />
                                                            <feBlend mode="normal" in2="effect2_innerShadow_335_148"
                                                                result="effect3_innerShadow_335_148" />
                                                        </filter>
                                                        <linearGradient id="paint0_linear_335_148" x1="145" y1="96"
                                                            x2="145" y2="186" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#D91C49" />
                                                            <stop offset="1" stop-color="#730F69" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>


                                            </v-col>
                                        </v-row>
                                    </v-tabs-window-item>

                                    <v-tabs-window-item value="tab-2">
                                        <v-row justify="space-between">
                                            <v-col cols="12" sm="6" align-self="center" order="2" order-sm="1">
                                                <p class="tab-content-text">
                                                    Our edge lies in campaign optimization — using a performance-based
                                                    marketing approach to ensure
                                                    every campaign is continuously refined for maximum ROI.
                                                </p>
                                            </v-col>
                                            <v-col cols="12" sm="5" align-self="center" order="1" order-sm="2"
                                                style="position: relative;">
                                                <svg class="svg-logo-xs px-4" v-if="$vuetify.display.xs" width="100%"
                                                    viewBox="0 0 327 183" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M50.6213 130.728C45.9062 127.974 46.2834 130.116 41.1534 122.965C20.822 94.8175 95.0561 49.1932 119.084 1.69494C90.077 0.585882 59.8251 -3.42967 44.586 13.3592C24.6695 35.3491 -5.50695 65.8673 0.867829 90.7637C4.1118 103.499 65.6718 175.74 74.7247 182.433C93.3587 166.6 108.258 136.77 126.326 123.691C145.564 141.895 158.54 164.038 177.928 182.471L327 1.23602C278.642 1.00656 265.742 -4.9594 242.242 25.3676L153.825 130.804C126.251 106.099 152.618 93.2112 165.518 70.4565H105.656C93.6227 73.0952 68.6517 115.316 50.6213 130.728Z"
                                                        fill="url(#paint0_linear_210_5461)" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M167.593 56.9948C186.83 56.9948 188.527 44.9864 198.561 33.4369C205.313 25.6736 218.93 10.6822 223.004 1.38903C166.046 0.471191 153.938 -6.2214 114.142 56.4212L167.593 57.0331V56.9948Z"
                                                        fill="url(#paint1_linear_210_5461)" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear_210_5461" x1="163.5"
                                                            y1="0.471191" x2="164.038" y2="143.122"
                                                            gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#C8C8F3" stop-opacity="0.15" />
                                                            <stop offset="1" stop-color="#C8C8F3" stop-opacity="0" />
                                                        </linearGradient>
                                                        <linearGradient id="paint1_linear_210_5461" x1="163.5"
                                                            y1="0.471191" x2="164.038" y2="143.122"
                                                            gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#C8C8F3" stop-opacity="0.15" />
                                                            <stop offset="1" stop-color="#C8C8F3" stop-opacity="0" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>

                                                <svg width="100%" viewBox="0 0 290 290" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <g filter="url(#filter0_dii_335_160)">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M120 96C108.954 96 100 104.954 100 116V166C100 177.046 108.954 186 120 186H170C181.046 186 190 177.046 190 166V116C190 104.954 181.046 96 170 96H120ZM169.363 125.178C168.139 130.356 164.859 137.416 157.076 146.164L156.268 154.292C156.052 156.24 155.181 158.056 153.797 159.442L145.756 167.494C145.232 168.02 144.43 168.15 143.767 167.818C143.103 167.485 142.726 166.765 142.831 166.03L143.746 159.62C143.88 158.674 143.699 157.618 143.3 156.393C143.044 155.607 142.76 154.912 142.46 154.176C142.369 153.954 142.277 153.727 142.184 153.494C139.432 152.828 136.858 151.258 134.787 149.184C132.716 147.11 131.148 144.531 130.484 141.775C130.25 141.681 130.021 141.588 129.799 141.497C129.066 141.197 128.374 140.914 127.589 140.658C126.366 140.259 125.312 140.077 124.367 140.212L117.967 141.128C117.233 141.233 116.514 140.856 116.182 140.191C115.85 139.526 115.98 138.724 116.505 138.198L124.545 130.146C125.929 128.76 127.743 127.887 129.688 127.67L129.708 127.668L137.809 126.865C146.391 119.235 153.418 115.965 158.604 114.701C161.609 113.969 163.971 113.918 165.623 114.068C166.448 114.143 167.091 114.268 167.546 114.382C167.774 114.438 167.954 114.492 168.086 114.535C168.709 114.737 169.027 114.975 169.271 115.528L169.273 115.531C169.308 115.611 169.339 115.692 169.369 115.774C169.416 115.905 169.476 116.083 169.539 116.308C169.665 116.758 169.807 117.394 169.899 118.209C170.085 119.845 170.071 122.184 169.363 125.178ZM148.914 134.902C150.934 136.924 154.753 136.378 157.446 133.681C160.138 130.985 160.684 127.159 158.665 125.137C156.645 123.114 152.826 123.661 150.133 126.357C147.44 129.054 146.895 132.879 148.914 134.902ZM139.655 154.968C137.473 153.814 135.395 152.235 133.568 150.405C131.629 148.463 129.973 146.238 128.805 143.906C125.086 147.254 122.173 160.964 122.599 161.391C123.021 161.814 135.88 158.366 139.655 154.968Z"
                                                            fill="url(#paint0_linear_335_160)" />
                                                    </g>
                                                    <defs>
                                                        <filter id="filter0_dii_335_160" x="0" y="0" width="290"
                                                            height="290" filterUnits="userSpaceOnUse"
                                                            color-interpolation-filters="sRGB">
                                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                            <feColorMatrix in="SourceAlpha" type="matrix"
                                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                result="hardAlpha" />
                                                            <feOffset dy="4" />
                                                            <feGaussianBlur stdDeviation="50" />
                                                            <feComposite in2="hardAlpha" operator="out" />
                                                            <feColorMatrix type="matrix"
                                                                values="0 0 0 0 0.754411 0 0 0 0 0.224383 0 0 0 0 0.383392 0 0 0 1 0" />
                                                            <feBlend mode="normal" in2="BackgroundImageFix"
                                                                result="effect1_dropShadow_335_160" />
                                                            <feBlend mode="normal" in="SourceGraphic"
                                                                in2="effect1_dropShadow_335_160" result="shape" />
                                                            <feColorMatrix in="SourceAlpha" type="matrix"
                                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                result="hardAlpha" />
                                                            <feOffset dx="5" dy="-13" />
                                                            <feGaussianBlur stdDeviation="13.4" />
                                                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1"
                                                                k3="1" />
                                                            <feColorMatrix type="matrix"
                                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.67 0" />
                                                            <feBlend mode="normal" in2="shape"
                                                                result="effect2_innerShadow_335_160" />
                                                            <feColorMatrix in="SourceAlpha" type="matrix"
                                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                result="hardAlpha" />
                                                            <feOffset dx="-4" dy="6" />
                                                            <feGaussianBlur stdDeviation="6.55" />
                                                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1"
                                                                k3="1" />
                                                            <feColorMatrix type="matrix"
                                                                values="0 0 0 0 0.93068 0 0 0 0 0.446542 0 0 0 0 0.561813 0 0 0 1 0" />
                                                            <feBlend mode="normal" in2="effect2_innerShadow_335_160"
                                                                result="effect3_innerShadow_335_160" />
                                                        </filter>
                                                        <linearGradient id="paint0_linear_335_160" x1="155" y1="96"
                                                            x2="155" y2="206" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#D91C49" />
                                                            <stop offset="1" stop-color="#730F69" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </v-col>
                                        </v-row>
                                    </v-tabs-window-item>

                                    <v-tabs-window-item value="tab-3">
                                        <v-row justify="space-between">
                                            <v-col cols="12" sm="6" align-self="center" order="2" order-sm="1">
                                                <p class="tab-content-text">
                                                    We don’t just set up and forget your campaign.
                                                    We actively manage and adjust it, making sure your iGaming marketing
                                                    stays ahead in the dynamic
                                                    market.
                                                </p>
                                            </v-col>
                                            <v-col cols="12" sm="5" align-self="center" order="1" order-sm="2"
                                                style="position: relative;">
                                                <svg class="svg-logo-xs px-4" v-if="$vuetify.display.xs" width="100%"
                                                    viewBox="0 0 327 183" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M50.6213 130.728C45.9062 127.974 46.2834 130.116 41.1534 122.965C20.822 94.8175 95.0561 49.1932 119.084 1.69494C90.077 0.585882 59.8251 -3.42967 44.586 13.3592C24.6695 35.3491 -5.50695 65.8673 0.867829 90.7637C4.1118 103.499 65.6718 175.74 74.7247 182.433C93.3587 166.6 108.258 136.77 126.326 123.691C145.564 141.895 158.54 164.038 177.928 182.471L327 1.23602C278.642 1.00656 265.742 -4.9594 242.242 25.3676L153.825 130.804C126.251 106.099 152.618 93.2112 165.518 70.4565H105.656C93.6227 73.0952 68.6517 115.316 50.6213 130.728Z"
                                                        fill="url(#paint0_linear_210_5462)" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M167.593 56.9948C186.83 56.9948 188.527 44.9864 198.561 33.4369C205.313 25.6736 218.93 10.6822 223.004 1.38903C166.046 0.471191 153.938 -6.2214 114.142 56.4212L167.593 57.0331V56.9948Z"
                                                        fill="url(#paint1_linear_210_5462)" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear_210_5462" x1="163.5"
                                                            y1="0.471191" x2="164.038" y2="143.122"
                                                            gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#C8C8F3" stop-opacity="0.15" />
                                                            <stop offset="1" stop-color="#C8C8F3" stop-opacity="0" />
                                                        </linearGradient>
                                                        <linearGradient id="paint1_linear_210_5462" x1="163.5"
                                                            y1="0.471191" x2="164.038" y2="143.122"
                                                            gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#C8C8F3" stop-opacity="0.15" />
                                                            <stop offset="1" stop-color="#C8C8F3" stop-opacity="0" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>

                                                <svg width="100%" viewBox="0 0 290 290" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <g filter="url(#filter0_dii_335_153)">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M120 96C108.954 96 100 104.954 100 116V166C100 177.046 108.954 186 120 186H170C181.046 186 190 177.046 190 166V116C190 104.954 181.046 96 170 96H120ZM149.875 109C150.865 109 151.667 109.797 151.667 110.781C151.667 111.765 152.469 112.562 153.458 112.562C154.448 112.562 155.25 113.36 155.25 114.344V116.125C155.25 117.109 154.448 117.906 153.458 117.906H135.542C134.552 117.906 133.75 117.109 133.75 116.125V114.344C133.75 113.36 134.552 112.562 135.542 112.562C136.531 112.562 137.333 111.765 137.333 110.781C137.333 109.797 138.135 109 139.125 109H149.875ZM128.375 112.562H130.473C130.274 113.12 130.167 113.719 130.167 114.344V116.125C130.167 119.076 132.573 121.469 135.542 121.469H153.458C156.427 121.469 158.833 119.076 158.833 116.125V114.344C158.833 113.719 158.726 113.12 158.527 112.562H160.625C163.594 112.562 166 114.955 166 117.906V160.656C166 163.608 163.594 166 160.625 166H128.375C125.406 166 123 163.608 123 160.656V117.906C123 114.955 125.406 112.562 128.375 112.562ZM156.583 144.625L151.591 128.082C151.356 127.303 150.62 126.781 149.803 126.814C148.986 126.847 148.294 127.425 148.123 128.221L144.211 146.373L140.841 135.207C140.63 134.507 140.011 134.007 139.279 133.944C138.547 133.881 137.851 134.269 137.522 134.922L132.643 144.625H131.958C130.969 144.625 130.167 145.422 130.167 146.406C130.167 147.39 130.969 148.188 131.958 148.188H133.75C134.429 148.188 135.049 147.806 135.353 147.203L138.707 140.533L142.784 154.043C143.019 154.822 143.755 155.344 144.572 155.311C145.389 155.278 146.081 154.7 146.252 153.904L150.164 135.752L153.534 146.918C153.761 147.672 154.459 148.188 155.25 148.188H157.042C158.031 148.188 158.833 147.39 158.833 146.406C158.833 145.422 158.031 144.625 157.042 144.625H156.583Z"
                                                            fill="url(#paint0_linear_335_153)" />
                                                    </g>
                                                    <defs>
                                                        <filter id="filter0_dii_335_153" x="0" y="0" width="290"
                                                            height="290" filterUnits="userSpaceOnUse"
                                                            color-interpolation-filters="sRGB">
                                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                            <feColorMatrix in="SourceAlpha" type="matrix"
                                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                result="hardAlpha" />
                                                            <feOffset dy="4" />
                                                            <feGaussianBlur stdDeviation="50" />
                                                            <feComposite in2="hardAlpha" operator="out" />
                                                            <feColorMatrix type="matrix"
                                                                values="0 0 0 0 0.754411 0 0 0 0 0.224383 0 0 0 0 0.383392 0 0 0 1 0" />
                                                            <feBlend mode="normal" in2="BackgroundImageFix"
                                                                result="effect1_dropShadow_335_153" />
                                                            <feBlend mode="normal" in="SourceGraphic"
                                                                in2="effect1_dropShadow_335_153" result="shape" />
                                                            <feColorMatrix in="SourceAlpha" type="matrix"
                                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                result="hardAlpha" />
                                                            <feOffset dx="5" dy="-13" />
                                                            <feGaussianBlur stdDeviation="13.4" />
                                                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1"
                                                                k3="1" />
                                                            <feColorMatrix type="matrix"
                                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.67 0" />
                                                            <feBlend mode="normal" in2="shape"
                                                                result="effect2_innerShadow_335_153" />
                                                            <feColorMatrix in="SourceAlpha" type="matrix"
                                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                result="hardAlpha" />
                                                            <feOffset dx="-4" dy="6" />
                                                            <feGaussianBlur stdDeviation="6.55" />
                                                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1"
                                                                k3="1" />
                                                            <feColorMatrix type="matrix"
                                                                values="0 0 0 0 0.93068 0 0 0 0 0.446542 0 0 0 0 0.561813 0 0 0 1 0" />
                                                            <feBlend mode="normal" in2="effect2_innerShadow_335_153"
                                                                result="effect3_innerShadow_335_153" />
                                                        </filter>
                                                        <linearGradient id="paint0_linear_335_153" x1="155" y1="96"
                                                            x2="155" y2="206" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#D91C49" />
                                                            <stop offset="1" stop-color="#730F69" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </v-col>
                                        </v-row>
                                    </v-tabs-window-item>
                                </v-tabs-window>
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: "AustriaPage",
    data: () => ({
        tab: 'tab-1',
    }),
    mounted() {
        this.createObserver();
    },
    methods: {
        createObserver() {
            const options = {
                root: null,
                threshold: 0.12,
            };
            const elements = document.querySelectorAll('.animate-block');
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                        observer.unobserve(entry.target);
                    }
                });
            }, options);
            elements.forEach(element => {
                observer.observe(element);
            });
        }
    }
}
</script>
<style>
#tabs-marketing .v-tab:not(.v-tab--selected) .v-tab__slider {
    opacity: 1;
    color: rgba(200, 200, 243, 0) !important;
}

#tabs-marketing .v-slide-group__content {
    width: 100%;
    justify-content: space-between;
    border-bottom: 2px solid rgba(200, 200, 243, 0.2);
}

@media (max-width: 768px) {

    #tabs-marketing .v-slide-group__content {
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        justify-content: space-between;
        border-bottom: 2px solid rgba(200, 200, 243, 0.2);
    }

    #tabs-marketing .v-btn__content {
        white-space: break-spaces;
        word-wrap: break-word;
        text-wrap: balance;
        width: min-content;
        margin: auto;
    }
}
</style>
<style scoped>
.svg-logo-xs {
    position: absolute;
    height: 100%;
    left: 0%;
    z-index: -1;
}

.animate-block {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animate-block.visible {
    opacity: 1;
    transform: translateY(0);
}

.card-main {
    background: linear-gradient(180deg, #000000 0%, #14081A 42%, #09040C 95%);
    border: 1px solid #FFFFFF1F;
    border-radius: 30px;
}

.tab-content-text {
    color: #C8C8F3;
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.tab-title {
    text-transform: capitalize;
    font-size: 1.05rem;
    font-weight: 400;
    letter-spacing: 0.5px;
    width: -webkit-min-content;
}

.block-title {
    background: linear-gradient(90deg, #DEE4EE 0%, #D91C49 57.5%, #DEE4EE 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 500;
    font-size: 2.2rem;
    letter-spacing: -0.07rem;
    text-align: center;
}

.container-main {
    padding-top: 10vh;
    padding-bottom: 10vh;
}

@media (max-width: 768px) {
    .block-title {
        font-size: 1.9rem;
    }

    .tab-title {
        font-size: 0.9rem;
        padding-left: 4px;
        padding-right: 4px;
        min-width: 40px;
        width: min-content;
        max-width: fit-content;
    }

    .tab-content-text {
        text-align: center;
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: -0.01rem;
        line-height: 1.9rem;
    }


}
</style>