<template>
  <headerTop v-if="!isLoading" />
  <Preloader :is-loading="isLoading" />
  <v-main id="main">
    <div ref="threeCanvas" id="threeCanvas" style="position: relative;">
    </div>
    <HelloPage />
    <OurPartners />
    <dividerRedLine />
    <AustriaPage />
    <dividerRedLine />
    <CPM />
    <dividerRedLine />
    <CPA />
    <rowInfinite />
    <WorkSteps />
    <PartnersAndCooperation />
    <BookCall />
    <GetInTouch />
    <footerComponent />
  </v-main>
</template>

<script>
import * as THREE from 'three'
import { defineComponent } from 'vue';
import Preloader from "../components/Preloader.vue";
import headerTop from "../components/header.vue";
import HelloPage from "../components/Home/1.HelloPage.vue";
import OurPartners from "../components/Home/2.OurPartners.vue";
import AustriaPage from "../components/Home/3.Austria.vue";
import CPM from "../components/Home/4.CPM.vue";
import CPA from "../components/Home/5.CPA.vue";
import WorkSteps from "../components/Home/6.WorkSteps.vue";
import PartnersAndCooperation from "../components/Home/7.Partners.vue";
import BookCall from "../components/Home/8.BookCall.vue";
import GetInTouch from "../components/Home/9.GetInTouch.vue";
import rowInfinite from "../components/rowInfinite.vue";
import dividerRedLine from "../components/Home/dividerRedLine.vue";
import footerComponent from "../components/footer.vue";

export default defineComponent({
  name: 'HomeView',
  components: {
    Preloader,
    headerTop,
    HelloPage,
    OurPartners,
    AustriaPage,
    CPM,
    CPA,
    WorkSteps,
    PartnersAndCooperation,
    BookCall,
    GetInTouch,
    rowInfinite,
    dividerRedLine,
    footerComponent
  },
  methods: {
    initThreeJS() {
      const renderer = new THREE.WebGLRenderer({
        antialias: false
      });

      this.$refs.threeCanvas.appendChild(renderer.domElement);

      //let heightContainer = window.innerHeight;
      let heightContainer = window.innerHeight;

      /* if (window.innerWidth < 768) {
        heightContainer = document.getElementById('threeCanvas').clientHeight;
      }*/

      renderer.setSize(window.innerWidth, heightContainer);

      const scene = new THREE.Scene();
      const camera = new THREE.OrthographicCamera(
        -0.5,
        0.5,
        0.5,
        -0.5,
        1e-5,
        100
      );
      camera.position.z = 1;

      window.addEventListener('resize', () => {

        //heightContainer = window.innerHeight;
        heightContainer = window.innerHeight;

        /* if (window.innerWidth < 768) {
          heightContainer = document.getElementById('threeCanvas').clientHeight;
        } */

        renderer.setSize(window.innerWidth, heightContainer);
      });


      const mouse = new THREE.Vector2();
      window.addEventListener('mousemove', (e) => {
        mouse.set(
          e.clientX / window.innerWidth - 0.5,
          (1 - (e.clientY / heightContainer)) - 0.5
        );
      });

      const geo = new THREE.PlaneGeometry(1, 1);
      const mat = new THREE.ShaderMaterial({
        depthTest: false,
        uniforms: {
          uTime: { value: 0 },
          uMouse: { value: mouse },
          //uColor: { value: new THREE.Color(0x00ff00) }
        },
        vertexShader: `
varying vec2 vUv;

void main() {
  vUv = uv;
  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}

  `,
        fragmentShader: `
  
uniform float uTime;
uniform vec2 uMouse;

varying vec2 vUv;

// Noise functions taken from
// https://gist.github.com/patriciogonzalezvivo/670c22f3966e662d2f83
//	Simplex 4D Noise 
//	by Ian McEwan, Ashima Arts
//
vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
float permute(float x){return floor(mod(((x*34.0)+1.0)*x, 289.0));}
vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
float taylorInvSqrt(float r){return 1.79284291400159 - 0.85373472095314 * r;}

vec4 grad4(float j, vec4 ip){
  const vec4 ones = vec4(1.0, 1.0, 1.0, -1.0);
  vec4 p,s;

  p.xyz = floor( fract (vec3(j) * ip.xyz) * 7.0) * ip.z - 1.0;
  p.w = 1.5 - dot(abs(p.xyz), ones.xyz);
  s = vec4(lessThan(p, vec4(0.0)));
  p.xyz = p.xyz + (s.xyz*2.0 - 1.0) * s.www; 

  return p;
}

float snoise(vec4 v){
  const vec2  C = vec2( 0.138196601125010504,  // (5 - sqrt(5))/20  G4
                        0.309016994374947451); // (sqrt(5) - 1)/4   F4
// First corner
  vec4 i  = floor(v + dot(v, C.yyyy) );
  vec4 x0 = v -   i + dot(i, C.xxxx);

// Other corners

// Rank sorting originally contributed by Bill Licea-Kane, AMD (formerly ATI)
  vec4 i0;

  vec3 isX = step( x0.yzw, x0.xxx );
  vec3 isYZ = step( x0.zww, x0.yyz );
//  i0.x = dot( isX, vec3( 1.0 ) );
  i0.x = isX.x + isX.y + isX.z;
  i0.yzw = 1.0 - isX;

//  i0.y += dot( isYZ.xy, vec2( 1.0 ) );
  i0.y += isYZ.x + isYZ.y;
  i0.zw += 1.0 - isYZ.xy;

  i0.z += isYZ.z;
  i0.w += 1.0 - isYZ.z;

  // i0 now contains the unique values 0,1,2,3 in each channel
  vec4 i3 = clamp( i0, 0.0, 1.0 );
  vec4 i2 = clamp( i0-1.0, 0.0, 1.0 );
  vec4 i1 = clamp( i0-2.0, 0.0, 1.0 );

  //  x0 = x0 - 0.0 + 0.0 * C 
  vec4 x1 = x0 - i1 + 1.0 * C.xxxx;
  vec4 x2 = x0 - i2 + 2.0 * C.xxxx;
  vec4 x3 = x0 - i3 + 3.0 * C.xxxx;
  vec4 x4 = x0 - 1.0 + 4.0 * C.xxxx;

// Permutations
  i = mod(i, 289.0); 
  float j0 = permute( permute( permute( permute(i.w) + i.z) + i.y) + i.x);
  vec4 j1 = permute( permute( permute( permute (
             i.w + vec4(i1.w, i2.w, i3.w, 1.0 ))
           + i.z + vec4(i1.z, i2.z, i3.z, 1.0 ))
           + i.y + vec4(i1.y, i2.y, i3.y, 1.0 ))
           + i.x + vec4(i1.x, i2.x, i3.x, 1.0 ));
// Gradients
// ( 7*7*6 points uniformly over a cube, mapped onto a 4-octahedron.)
// 7*7*6 = 294, which is close to the ring size 17*17 = 289.

  vec4 ip = vec4(1.0/294.0, 1.0/49.0, 1.0/7.0, 0.0) ;

  vec4 p0 = grad4(j0,   ip);
  vec4 p1 = grad4(j1.x, ip);
  vec4 p2 = grad4(j1.y, ip);
  vec4 p3 = grad4(j1.z, ip);
  vec4 p4 = grad4(j1.w, ip);

// Normalise gradients
  vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
  p0 *= norm.x;
  p1 *= norm.y;
  p2 *= norm.z;
  p3 *= norm.w;
  p4 *= taylorInvSqrt(dot(p4,p4));

// Mix contributions from the five corners
  vec3 m0 = max(0.6 - vec3(dot(x0,x0), dot(x1,x1), dot(x2,x2)), 0.0);
  vec2 m1 = max(0.6 - vec2(dot(x3,x3), dot(x4,x4)            ), 0.0);
  m0 = m0 * m0;
  m1 = m1 * m1;
  return 49.0 * ( dot(m0*m0, vec3( dot( p0, x0 ), dot( p1, x1 ), dot( p2, x2 )))
               + dot(m1*m1, vec2( dot( p3, x3 ), dot( p4, x4 ) ) ) ) ;

}

void main() {
  float noise = snoise(vec4(vUv, uMouse + uTime * 0.0001));
  //gl_FragColor = vec4(noise);

    vec3 redColor = vec3(217.0 / 255.0, 28.0 / 255.0, 73.0 / 255.0);
    gl_FragColor = vec4(redColor * noise, 0.20);  // Output red with noise modulation
}
  
  `
      });

      const plane = new THREE.Mesh(geo, mat);

      scene.add(plane);

      const animate = () => {

        mat.uniforms.uTime.value = performance.now();

        renderer.render(scene, camera);
        requestAnimationFrame(animate);
      };

      requestAnimationFrame(animate);
    },
  },
  data: () => ({
    isLoading: true,
  }),
  mounted() {
    this.initThreeJS();

    let vue = this;
    if (document.readyState == "complete") {
      vue.isLoading = false;
    } else {
      document.addEventListener("PreloaderFinish", ()=>{
        vue.isLoading = false;

      })
     /*  window.addEventListener("load", () => {
        vue.isLoading = false;
      }); */
    }
  },
});
</script>
<style>
#threeCanvas>canvas {
  position: fixed;
}
</style>
<style scoped>
#threeCanvas {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
}

#threeCanvas::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  pointer-events: none;
}
</style>
