<template>
    <v-container fluid class="container-main">
        <v-row id="cpm" justify="center">
            <v-col cols="12" md="10">
                <v-row justify="space-between">
                    <v-col cols="12">
                        <v-card class="card-main px-6 px-sm-10 py-10 py-sm-15 animate-block">
                            <h2 class="card-title mt-sm-6 animate-block">
                                CPM Advertising
                            </h2>
                            <v-row justify="center">
                                <v-col cols="12" sm="10">
                                    <p class="card-suptitle mt-2 animate-block">Our CPM <span style="color: #D91C49;">(Cost Per
                                            Mille)</span>
                                        advertising solutions are designed to
                                        provide your brand with
                                        maximum exposure across the world’s leading sports platforms. </p>
                                </v-col>
                            </v-row>
                            <v-row :justify="$vuetify.display.smAndUp ? 'center' : 'start'">
                                <v-col cols="10" sm="4" lg="3">
                                    <v-btn href="https://calendly.com/office-work-o/15min" target="_blank" variant="flat" color="#C8C8F3" block class="card-btn px-4 fontSFProR animate-block">
                                        <template v-slot:append>
                                            <v-icon class="mr-1">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M15.5 1.125C15.5 0.779822 15.2202 0.5 14.875 0.5H7.375C7.02982 0.5 6.75 0.779822 6.75 1.125C6.75 1.47018 7.02982 1.75 7.375 1.75H13.3661L0.683058 14.4331C0.43898 14.6771 0.43898 15.0729 0.683058 15.3169C0.927136 15.561 1.32286 15.561 1.56694 15.3169L14.25 2.63388V8.625C14.25 8.97018 14.5298 9.25 14.875 9.25C15.2202 9.25 15.5 8.97018 15.5 8.625V1.125Z"
                                                        fill="#D91C49" />
                                                </svg>
                                            </v-icon>
                                        </template>
                                        book a call
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row justify="center" class="mt-10">
                                <v-col cols="12" sm="4" class="px-2">
                                    <v-card class="card-small animate-block">
                                        <div style="line-height: 0px;">
                                            <svg width="45" viewBox="0 0 45 44" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_155_879)">
                                                    <rect x="0.5" width="44" height="44" rx="22" fill="#1C1016" />
                                                    <path
                                                        d="M22.25 14.0381C17.8317 14.0381 14.25 17.6198 14.25 22.0381C14.25 26.4564 17.8317 30.0381 22.25 30.0381C26.6683 30.0381 30.25 26.4564 30.25 22.0381C30.25 17.6198 26.6683 14.0381 22.25 14.0381ZM16.2904 18.3643C16.6155 19.6929 18.8219 20.9034 20.0065 21.5534C20.4872 21.8171 20.7996 21.9885 20.75 22.0381C20.6693 22.1188 20.5877 22.1965 20.5078 22.2726C20.0924 22.6679 19.7215 23.0211 19.75 23.5381C19.785 24.1725 20.3678 24.3617 20.9642 24.5555C21.5415 24.743 22.1316 24.9346 22.25 25.5381C22.3319 25.9555 22.1755 26.5262 22.0297 27.0578C21.8152 27.8403 21.6239 28.5381 22.25 28.5381C23.75 28.0381 26.0484 25.3525 26.25 23.5381C26.3881 22.2955 24.25 21.5381 22.75 21.0381C22.272 20.8788 21.9945 21.1192 21.7597 21.3225C21.5876 21.4717 21.4384 21.6009 21.25 21.5381C20.8047 21.3897 18.75 19.5381 19.75 19.0381C20.5308 18.6477 20.7019 18.8669 20.9774 19.2198C21.0548 19.3189 21.1404 19.4285 21.25 19.5381C21.8585 19.8423 21.9116 19.4061 21.9725 18.9052C22.0118 18.5829 22.0542 18.2338 22.25 18.0381C22.6836 17.6045 23.5148 17.2468 24.2777 16.9186C24.9903 16.612 25.6433 16.331 25.857 16.0377C27.8902 17.2625 29.25 19.4915 29.25 22.0381C29.25 25.9041 26.116 29.0381 22.25 29.0381C18.384 29.0381 15.25 25.9041 15.25 22.0381C15.25 20.6907 15.6307 19.4321 16.2904 18.3643Z"
                                                        fill="#D91C49" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_155_879">
                                                        <rect width="44" height="44" fill="white"
                                                            transform="translate(0.5)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div class="card-small-title my-2">
                                            Global reach
                                        </div>
                                        <div class="card-small-subtitle">
                                            We have established partnerships with nearly every major sports news
                                            publisher globally, ensuring your
                                            ads are strategically placed where your target audience is most active and
                                            engaged.
                                        </div>
                                    </v-card>
                                </v-col>

                                <v-col cols="12" sm="4" class="px-2">
                                    <v-card class="card-small animate-block">
                                        <div style="line-height: 0px;">
                                            <svg width="45" height="44" viewBox="0 0 45 44" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_192_1038)">
                                                    <rect x="0.5" width="44" height="44" rx="22" fill="#1C1016" />
                                                    <path opacity="0.2"
                                                        d="M32.125 22.8438C32.125 24.5522 29.0467 25.9375 25.25 25.9375C23.5656 25.9375 22.0222 25.6651 20.8268 25.2122C24.1122 24.9793 26.625 23.7031 26.625 22.1562V19.8119C29.7634 20.0989 32.125 21.3467 32.125 22.8438ZM26.625 18.7188C26.625 17.0103 23.5467 15.625 19.75 15.625C15.9533 15.625 12.875 17.0103 12.875 18.7188C12.875 20.4272 15.9533 21.8125 19.75 21.8125C23.5467 21.8125 26.625 20.4272 26.625 18.7188Z"
                                                        fill="#D91C49" />
                                                    <path
                                                        d="M27.3125 19.1974V18.7188C27.3125 16.5634 24.0615 14.9375 19.75 14.9375C15.4385 14.9375 12.1875 16.5634 12.1875 18.7188V22.1562C12.1875 23.9515 14.4434 25.378 17.6875 25.8052V26.2812C17.6875 28.4366 20.9385 30.0625 25.25 30.0625C29.5615 30.0625 32.8125 28.4366 32.8125 26.2812V22.8438C32.8125 21.0648 30.628 19.6366 27.3125 19.1974ZM31.4375 22.8438C31.4375 23.9798 28.7915 25.25 25.25 25.25C24.9295 25.25 24.6115 25.2388 24.2978 25.2182C26.1515 24.5427 27.3125 23.4453 27.3125 22.1562V20.587C29.8795 20.9695 31.4375 22.0076 31.4375 22.8438ZM17.6875 24.4121V22.3677C18.3714 22.4568 19.0603 22.501 19.75 22.5C20.4397 22.501 21.1286 22.4568 21.8125 22.3677V24.4121C21.1296 24.513 20.4403 24.5633 19.75 24.5625C19.0597 24.5633 18.3704 24.513 17.6875 24.4121ZM25.9375 20.9471V22.1562C25.9375 22.8773 24.871 23.6516 23.1875 24.1216V22.1133C24.297 21.8443 25.2362 21.4438 25.9375 20.9471ZM19.75 16.3125C23.2915 16.3125 25.9375 17.5827 25.9375 18.7188C25.9375 19.8548 23.2915 21.125 19.75 21.125C16.2085 21.125 13.5625 19.8548 13.5625 18.7188C13.5625 17.5827 16.2085 16.3125 19.75 16.3125ZM13.5625 22.1562V20.9471C14.2638 21.4438 15.203 21.8443 16.3125 22.1133V24.1216C14.629 23.6516 13.5625 22.8773 13.5625 22.1562ZM19.0625 26.2812V25.9229C19.2885 25.9315 19.5171 25.9375 19.75 25.9375C20.0834 25.9375 20.4091 25.9263 20.7288 25.9074C21.084 26.0346 21.4457 26.1424 21.8125 26.2305V28.2466C20.129 27.7766 19.0625 27.0023 19.0625 26.2812ZM23.1875 28.5371V26.4875C23.8712 26.5794 24.5602 26.6253 25.25 26.625C25.9397 26.626 26.6286 26.5818 27.3125 26.4927V28.5371C25.9448 28.7376 24.5552 28.7376 23.1875 28.5371ZM28.6875 28.2466V26.2383C29.797 25.9693 30.7362 25.5688 31.4375 25.0721V26.2812C31.4375 27.0023 30.371 27.7766 28.6875 28.2466Z"
                                                        fill="#D91C49" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_192_1038">
                                                        <rect width="44" height="44" fill="white"
                                                            transform="translate(0.5)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div class="card-small-title my-2">
                                            Optimised for Cost
                                        </div>
                                        <div class="card-small-subtitle">
                                            We focus on keeping your costs optimized, ensuring you get the most value
                                            out of every impression while
                                            maintaining high levels of audience engagement.
                                        </div>
                                    </v-card>
                                </v-col>

                                <v-col cols="12" sm="4" class="px-2">
                                    <v-card class="card-small animate-block">
                                        <div style="line-height: 0px;">
                                            <svg width="45" viewBox="0 0 45 44" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_192_1060)">
                                                    <rect x="0.5" width="44" height="44" rx="22" fill="#1C1016" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M17.35 25.2381C17.4444 25.1122 17.5926 25.0381 17.75 25.0381H20.25C20.5261 25.0381 20.75 25.2619 20.75 25.5381C20.75 25.8142 20.5261 26.0381 20.25 26.0381H18L15.75 29.0381H28.75L26.5 26.0381H24.25C23.9739 26.0381 23.75 25.8142 23.75 25.5381C23.75 25.2619 23.9739 25.0381 24.25 25.0381H26.75C26.9074 25.0381 27.0556 25.1122 27.15 25.2381L30.15 29.2381C30.2636 29.3896 30.2819 29.5923 30.1972 29.7617C30.1125 29.9311 29.9394 30.0381 29.75 30.0381H14.75C14.5606 30.0381 14.3875 29.9311 14.3028 29.7617C14.2181 29.5923 14.2364 29.3896 14.35 29.2381L17.35 25.2381Z"
                                                        fill="#D91C49" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M18.25 18.0381C18.25 15.8289 20.0409 14.0381 22.25 14.0381C24.4591 14.0381 26.25 15.8289 26.25 18.0381C26.25 20.0779 24.7232 21.7611 22.75 22.0071V27.5381C22.75 27.8142 22.5261 28.0381 22.25 28.0381C21.9739 28.0381 21.75 27.8142 21.75 27.5381V22.0071C19.7768 21.7611 18.25 20.0779 18.25 18.0381Z"
                                                        fill="#D91C49" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_192_1060">
                                                        <rect width="44" height="44" fill="white"
                                                            transform="translate(0.5)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div class="card-small-title my-2">
                                            Strategic Ad Placement
                                        </div>
                                        <div class="card-small-subtitle">
                                            We place your ads in the most relevant contexts, enhancing visibility and
                                            impact on the right audiences.
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: "CRM_Advertising",
    mounted() {
        this.createObserver();
    },
    methods: {
        createObserver() {
            const options = {
                root: null,
                threshold: 0.12,
            };
            const elements = document.querySelectorAll('.animate-block');
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                        observer.unobserve(entry.target);
                    }
                });
            }, options);
            elements.forEach(element => {
                observer.observe(element);
            });
        }
    }
}
</script>
<style scoped>
.animate-block {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animate-block.visible {
    opacity: 1;
    transform: translateY(0);
}

.card-small-subtitle {
    font-size: 1.05rem;
    letter-spacing: -0.02rem;
    color: #997B8D;
}

.card-small-title {
    font-size: 1.5rem;
    letter-spacing: -0.03rem;
    color: #C8C8F3;
    text-shadow: 0px 4px 8.4px #000000DE;
}

.card-small {
    border: 1px solid #D91C49;
    background: #251B2B45;
    border-radius: 20px;
    padding: 30px 20px;
    height: 100%;
}

.card-btn {
    color: #1C1016;
    font-weight: 500;
    font-size: 1.05rem;
    letter-spacing: -0.03rem;
    border-radius: 32px;
    text-align: left;
    justify-content: space-between;
    box-shadow: unset;
}

.card-suptitle {
    color: #997B8D;
    text-align: center;
    font-size: 1.05rem;
    letter-spacing: -0.02rem;
}

.card-title {
    background: linear-gradient(90deg, #C1285F 35%, #F7F8F8 77%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 500;
    font-size: 2.3rem;
    letter-spacing: -0.07rem;
    text-align: center;
}

.card-main {
    background: linear-gradient(180deg, #000000 0%, #14081A 42%, #09040C 95%);
    border: 1px solid #FFFFFF1F;
    border-radius: 30px;
}

.container-main {
    padding-top: 10vh;
    padding-bottom: 10vh;
}

@media (min-device-width: 220px) and (max-device-width: 768px) {
    .card-suptitle {
        text-align: left;
        font-size: 1rem;
    }

    .card-title {
        text-align: left;
        font-size: 2rem;
    }

    .card-small-title {
        font-size: 1.35rem;
    }

    .card-small-subtitle {
        font-size: 0.95rem;
    }
}
</style>