<template>
    <v-row justify="center" v-if="$vuetify.display.xs">
        <v-col cols="12">
            <svg width="100%" viewBox="0 0 355 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="0.5" y1="1.97119" x2="354.5" y2="1.97119" stroke="url(#paint0_linear_262_261)"
                    stroke-width="3" />
                <defs>
                    <linearGradient id="paint0_linear_262_261" x1="0.5" y1="3.97119" x2="396.39" y2="3.97119"
                        gradientUnits="userSpaceOnUse">
                        <stop offset="0.095" stop-color="#D91C49" stop-opacity="0" />
                        <stop offset="0.405" stop-color="#B0173B" stop-opacity="0.818182" />
                        <stop offset="0.495" stop-color="#A71538" />
                        <stop offset="0.839353" stop-color="#730F27" stop-opacity="0" />
                    </linearGradient>
                </defs>
            </svg>
        </v-col>
    </v-row>
</template>
<script>

</script>
<style scoped></style>