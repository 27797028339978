import { createRouter, createWebHistory } from "vue-router";
import HomeView from '../views/HomeView.vue'
import LegalView from "../views/Legal.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: `/legal/:name?`,
    name: "legal",
    component: LegalView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
