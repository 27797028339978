<template>
  <v-fade-transition>
    <div class="preloader" v-if="loading">
      <v-img min-width="120px" max-width="200px" alt="work-logo" class="img-logo-big"
        src="../assets/img/logo.png"></v-img>
    </div>
  </v-fade-transition>
</template>

<script>
export default {
  name: "PreloaderPage",
  props: {
    isLoading: Boolean,
  },
  data: () => ({
    selected: "",
    lap: false,
  }),
  mounted() {
    const PreloaderFinish = new Event("PreloaderFinish");

    if (document.readyState == "complete") {
      this.lap = true;
      document.dispatchEvent(PreloaderFinish);
      return
    } else {
      setTimeout(() => {
        this.lap = true;
        document.dispatchEvent(PreloaderFinish);
      }, 1500);
    }
  },
  computed: {
    loading() {
      if (this.isLoading == true) {
        return true;
      } else if (this.lap == false) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(20, 21, 23, 0.6);
  backdrop-filter: blur(10px);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(246, 242, 230, 1);
}
</style>