<template>
    <v-container fluid class="container-main">
        <v-row justify="center">
            <v-col cols="12" md="10">
                <v-row justify="space-between">
                    <v-col cols="12">
                        <v-card class="card-main px-6 px-sm-10 py-10 py-sm-15 animate-block">
                            <h2 class="card-title mt-sm-6 animate-block">
                                CPA Advertising
                            </h2>
                            <v-row justify="center">
                                <v-col cols="12" sm="10">
                                    <p class="card-suptitle mt-2 animate-block">
                                        Our CPA <span style="color: #D91C49;">(Cost Per Acquisition)</span> advertising
                                        services are meticulously designed to
                                        drive conversions and deliver high-quality players to your platform. We leverage
                                        the power of major digital advertising platforms like Facebook, Google, and PPC
                                        (Pay-Per-Click) networks to target users who are most likely to convert.
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row :justify="$vuetify.display.smAndUp ? 'center' : 'start'">
                                <v-col cols="10" sm="4" lg="3">
                                    <v-btn href="https://calendly.com/office-work-o/15min" target="_blank" variant="flat" color="#C8C8F3" block class="card-btn px-4 fontSFProR animate-block">
                                        <template v-slot:append>
                                            <v-icon class="mr-1">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M15.5 1.125C15.5 0.779822 15.2202 0.5 14.875 0.5H7.375C7.02982 0.5 6.75 0.779822 6.75 1.125C6.75 1.47018 7.02982 1.75 7.375 1.75H13.3661L0.683058 14.4331C0.43898 14.6771 0.43898 15.0729 0.683058 15.3169C0.927136 15.561 1.32286 15.561 1.56694 15.3169L14.25 2.63388V8.625C14.25 8.97018 14.5298 9.25 14.875 9.25C15.2202 9.25 15.5 8.97018 15.5 8.625V1.125Z"
                                                        fill="#D91C49" />
                                                </svg>
                                            </v-icon>
                                        </template>
                                        book a call
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row justify="center" class="mt-10">
                                <v-col cols="12" sm="4" class="px-2">
                                    <v-card class="card-small animate-block">
                                        <div style="line-height: 0px;">
                                            <svg width="45" viewBox="0 0 45 44" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_192_1072)">
                                                    <rect x="0.5" width="44" height="44" rx="22" fill="#1C1016" />
                                                    <path
                                                        d="M16.25 16.0381C16.25 14.9335 17.1454 14.0381 18.25 14.0381H26.25C27.3546 14.0381 28.25 14.9335 28.25 16.0381V28.0381C28.25 29.1427 27.3546 30.0381 26.25 30.0381H18.25C17.1454 30.0381 16.25 29.1427 16.25 28.0381V16.0381ZM23.25 27.0381C23.25 26.4858 22.8023 26.0381 22.25 26.0381C21.6977 26.0381 21.25 26.4858 21.25 27.0381C21.25 27.5904 21.6977 28.0381 22.25 28.0381C22.8023 28.0381 23.25 27.5904 23.25 27.0381Z"
                                                        fill="#D91C49" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_192_1072">
                                                        <rect width="44" height="44" fill="white"
                                                            transform="translate(0.5)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div class="card-small-title my-2">
                                            Targeted CPA Advertising
                                        </div>
                                        <div class="card-small-subtitle">
                                            Beyond traditional digital channels, we also specialize in ASO (App Store
                                            Optimization) ads, ensuring
                                            your gaming apps rank highly and attract the right users.
                                        </div>
                                    </v-card>
                                </v-col>

                                <v-col cols="12" sm="4" class="px-2">
                                    <v-card class="card-small animate-block">
                                        <div style="line-height: 0px;">
                                            <svg width="45" viewBox="0 0 45 44" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_192_1094)">
                                                    <rect x="0.5" width="44" height="44" rx="22" fill="#1C1016" />
                                                    <path
                                                        d="M22.25 30.0381C25.5637 30.0381 28.25 28.0381 28.25 24.5381C28.25 23.0381 27.75 20.5381 25.75 18.5381C26 20.0381 24.5 20.5381 24.5 20.5381C25.25 18.0381 23.25 14.5381 20.25 14.0381C20.6071 16.0381 20.75 18.0381 18.25 20.0381C17 21.0381 16.25 22.767 16.25 24.5381C16.25 28.0381 18.9363 30.0381 22.25 30.0381ZM22.25 29.0381C20.5931 29.0381 19.25 28.0381 19.25 26.2881C19.25 25.5381 19.5 24.2881 20.5 23.2881C20.375 24.0381 21.25 24.5381 21.25 24.5381C20.875 23.2881 21.75 21.2881 23.25 21.0381C23.0714 22.0381 23 23.0381 24.25 24.0381C24.875 24.5381 25.25 25.4026 25.25 26.2881C25.25 28.0381 23.9069 29.0381 22.25 29.0381Z"
                                                        fill="#D91C49" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_192_1094">
                                                        <rect width="44" height="44" fill="white"
                                                            transform="translate(0.5)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div class="card-small-title my-2">
                                            Performance-Driven Campaigns
                                        </div>
                                        <div class="card-small-subtitle">
                                            By focusing on performance-based metrics, we tailor each campaign to meet
                                            your specific goals — whether
                                            that’s acquiring new players, increasing deposits, or boosting player
                                            retention.
                                        </div>
                                    </v-card>
                                </v-col>

                                <v-col cols="12" sm="4" class="px-2">
                                    <v-card class="card-small animate-block">
                                        <div style="line-height: 0px;">
                                            <svg width="45" viewBox="0 0 45 44" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_192_1083)">
                                                    <rect x="0.5" width="44" height="44" rx="22" fill="#1C1016" />
                                                    <path
                                                        d="M14.25 19.0381C14.25 21.4665 15.9812 23.4906 18.2768 23.9434C18.3433 23.2103 18.5316 22.5123 18.8212 21.8698C17.9446 21.5745 17.3821 20.9023 17.249 19.9489H16.75V19.5226H17.2157V19.0885C17.2157 19.042 17.2157 18.9955 17.2199 18.9528H16.75V18.5265H17.2614C17.486 17.2784 18.4631 16.5381 19.9309 16.5381C20.2469 16.5381 20.5213 16.5691 20.75 16.6234V17.3559C20.5296 17.3017 20.2594 17.2745 19.935 17.2745C19.0161 17.2745 18.3966 17.7396 18.2011 18.5265H20.118V18.9528H18.1388C18.1346 18.9993 18.1346 19.0497 18.1346 19.1001V19.5226H20.118V19.9489H18.1804C18.2983 20.551 18.6485 20.9795 19.1848 21.1778C20.2268 19.4558 22.0456 18.2563 24.1554 18.0649C23.7025 15.7693 21.6784 14.0381 19.25 14.0381C16.4886 14.0381 14.25 16.2767 14.25 19.0381ZM30.25 24.5381C30.25 27.5757 27.7876 30.0381 24.75 30.0381C21.7124 30.0381 19.25 27.5757 19.25 24.5381C19.25 21.5005 21.7124 19.0381 24.75 19.0381C27.7876 19.0381 30.25 21.5005 30.25 24.5381ZM22.5 25.8602C22.5687 26.6946 23.2462 27.3449 24.4639 27.4219V28.0381H25.0041V27.4177C26.263 27.3321 27 26.6775 27 25.7276C27 24.8633 26.4369 24.4183 25.4298 24.1872L25.0041 24.0888V22.4116C25.5443 22.4715 25.8876 22.7581 25.97 23.1561H26.9176C26.8489 22.3517 26.1394 21.7227 25.0041 21.6542V21.0381H24.4639V21.6671C23.3881 21.7697 22.6556 22.3987 22.6556 23.2887C22.6556 24.076 23.2004 24.5766 24.1068 24.782L24.4639 24.8675V26.6475C23.91 26.5662 23.5437 26.271 23.4613 25.8602H22.5ZM24.4593 23.9647C23.9283 23.8449 23.6399 23.601 23.6399 23.2331C23.6399 22.8223 23.9512 22.5142 24.4639 22.4244V23.9647H24.4593ZM25.0819 25.0087C25.7274 25.1542 26.0249 25.3896 26.0249 25.8046C26.0249 26.2795 25.6541 26.6047 25.0041 26.6646V24.9916L25.0819 25.0087Z"
                                                        fill="#D91C49" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_192_1083">
                                                        <rect width="44" height="44" fill="white"
                                                            transform="translate(0.5)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div class="card-small-title my-2">
                                            Analytics & Insights
                                        </div>
                                        <div class="card-small-subtitle">
                                            We track every click, conversion, and player action, providing you with
                                            transparent insights. Our
                                            detailed reporting ensures you have the data you need to understand and
                                            enhance your campaign’s
                                            performance.
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: "CPA_Advertising",
    mounted() {
        this.createObserver();
    },
    methods: {
        createObserver() {
            const options = {
                root: null,
                threshold: 0.12,
            };
            const elements = document.querySelectorAll('.animate-block');
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                        observer.unobserve(entry.target);
                    }
                });
            }, options);
            elements.forEach(element => {
                observer.observe(element);
            });
        }
    }
}
</script>
<style scoped>
.animate-block {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animate-block.visible {
    opacity: 1;
    transform: translateY(0);
}

.card-small-subtitle {
    font-size: 1.05rem;
    letter-spacing: -0.02rem;
    color: #997B8D;
}

.card-small-title {
    font-size: 1.5rem;
    letter-spacing: -0.03rem;
    color: #C8C8F3;
    text-shadow: 0px 4px 8.4px #000000DE;
}

.card-small {
    border: 1px solid #D91C49;
    background: #251B2B45;
    border-radius: 20px;
    padding: 30px 20px;
    height: 100%;
}

.card-btn {
    color: #1C1016;
    font-weight: 500;
    font-size: 1.05rem;
    letter-spacing: -0.03rem;
    border-radius: 32px;
    text-align: left;
    justify-content: space-between;
    box-shadow: unset;
}

.card-suptitle {
    color: #997B8D;
    text-align: center;
    font-size: 1.05rem;
    letter-spacing: -0.02rem;
}

.card-title {
    background: linear-gradient(90deg, #C1285F 35%, #F7F8F8 77%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 500;
    font-size: 2.3rem;
    letter-spacing: -0.07rem;
    text-align: center;
}

.card-main {
    background: linear-gradient(180deg, #000000 0%, #14081A 42%, #09040C 95%);
    border: 1px solid #FFFFFF1F;
    padding: 60px 40px;
    border-radius: 30px;
}

.container-main {
    padding-top: 10vh;
    padding-bottom: 10vh;
}

@media (min-device-width: 220px) and (max-device-width: 768px) {
    .card-suptitle {
        text-align: left;
        font-size: 1rem;
    }

    .card-title {
        text-align: left;
        font-size: 2rem;
    }

    .card-small-title {
        font-size: 1.35rem;
    }

    .card-small-subtitle {
        font-size: 0.95rem;
    }
}
</style>