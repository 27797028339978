<template>
    <v-container fluid class="container-main">
        <v-row justify="center">
            <v-col cols="12" md="10">
                <v-row justify="space-between" class="mb-2">
                    <v-col cols="12" md="12" align-self="center">
                        <h3 class="text-title animate-block">
                            Partners and cooperation we are proud of
                        </h3>
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col cols="12">
                        <v-card class="card-main px-6 pt-10 px-sm-10 pt-sm-15 pb-5 my-8 animate-block">
                            <v-row justify="center">
                                <v-col cols="12" sm="4" align-self="center">
                                    <v-row>
                                        <v-col cols="auto" class="pa-1">
                                            <div class="div-hashtag">
                                                Casino
                                            </div>
                                        </v-col>
                                        <v-col cols="auto" class="pa-1">
                                            <div class="div-hashtag">
                                                In-App ads
                                            </div>
                                        </v-col>
                                        <v-col cols="auto" class="pa-1">
                                            <div class="div-hashtag">
                                                Slot games
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="10" sm="12">
                                            <v-img width="100%" class="mt-2" src="../../assets/img/home/p1.png"></v-img>
                                        </v-col>
                                    </v-row>
                                    <h4 class="card-title-text mt-3 mb-1">Marketing Campaign for PIN-UP</h4>
                                    <p class="card-suptitle">Austria, 2023</p>
                                </v-col>
                                <v-col cols="12" sm="5" offset-sm="1">
                                    <p class="card-content-text">
                                        Our partnership with Pin-Up Casino has spanned multiple GEOs, focusing on both
                                        sports and gambling ad campaigns. We’ve utilized a variety of traffic sources,
                                        ranging from In-App ads to IAB banners, ensuring wide visibility across diverse
                                        platforms. To maximize results, we designed creatives tailored to the most
                                        popular slot games in each region, leading to high conversion rates. This custom
                                        approach has helped Pin-Up see impressive results, making them a stable and
                                        valued partner in our collaborative journey.</p>
                                </v-col>
                            </v-row>

                            <v-divider color="#FFF" class="mt-8 mb-3"></v-divider>

                            <v-btn href="https://pin-up.global/" target="_blank" class="card-btn" variant="text" block
                                size="large" color="#997B8D">
                                Open official website
                                <template v-slot:append>
                                    <v-icon class="mr-1">
                                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" width="32" height="32" rx="16" fill="#1C1016" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M13.1464 9.15771C13.3417 8.94743 13.6583 8.94743 13.8536 9.15771L19.8536 15.6193C20.0488 15.8295 20.0488 16.1705 19.8536 16.3808L13.8536 22.8423C13.6583 23.0526 13.3417 23.0526 13.1464 22.8423C12.9512 22.632 12.9512 22.2911 13.1464 22.0808L18.7929 16L13.1464 9.91921C12.9512 9.70893 12.9512 9.36799 13.1464 9.15771Z"
                                                fill="#D91C49" />
                                        </svg>
                                    </v-icon>
                                </template>
                            </v-btn>
                        </v-card>

                        <v-card class="card-main px-6 pt-10 px-sm-10 pt-sm-15 pb-5 my-8 animate-block">
                            <v-row justify="center">
                                <v-col cols="12" sm="4" align-self="center">
                                    <v-row>
                                        <v-col cols="auto" class="pa-1">
                                            <div class="div-hashtag">
                                                Market presence
                                            </div>
                                        </v-col>
                                        <v-col cols="auto" class="pa-1">
                                            <div class="div-hashtag">
                                                CPM
                                            </div>
                                        </v-col>
                                        <v-col cols="auto" class="pa-1">
                                            <div class="div-hashtag">
                                                iGaming
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="10" sm="12">
                                            <v-img width="100%" class="mt-2" src="../../assets/img/home/p2.png"></v-img>
                                        </v-col>
                                    </v-row>
                                    <h4 class="card-title-text mt-3 mb-1">IDN Play CPM Campaign</h4>
                                    <p class="card-suptitle">Austria, 2023</p>
                                </v-col>
                                <v-col cols="12" sm="5" offset-sm="1">
                                    <p class="card-content-text">We partnered with IDN Play, a leading iGaming holding
                                        in Indonesia, to help
                                        them expand their reach through a highly targeted CPM campaign. By delivering
                                        250 million impressions
                                        across various traffic sources, we achieved exceptional results in both
                                        conversion rates and brand
                                        awareness. Our custom strategy ensured maximum visibility, leading to a
                                        significant boost in their
                                        market presence. The campaign’s success solidified IDN Play as a key player in
                                        the region, and we're
                                        proud to have played a role in driving their growth.</p>
                                </v-col>
                            </v-row>

                            <v-divider color="#FFF" class="mt-8 mb-3"></v-divider>

                            <v-btn href="https://www.idnplay.com/" target="_blank" class="card-btn" variant="text" block
                                size="large" color="#997B8D">
                                Open official website
                                <template v-slot:append>
                                    <v-icon class="mr-1">
                                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" width="32" height="32" rx="16" fill="#1C1016" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M13.1464 9.15771C13.3417 8.94743 13.6583 8.94743 13.8536 9.15771L19.8536 15.6193C20.0488 15.8295 20.0488 16.1705 19.8536 16.3808L13.8536 22.8423C13.6583 23.0526 13.3417 23.0526 13.1464 22.8423C12.9512 22.632 12.9512 22.2911 13.1464 22.0808L18.7929 16L13.1464 9.91921C12.9512 9.70893 12.9512 9.36799 13.1464 9.15771Z"
                                                fill="#D91C49" />
                                        </svg>
                                    </v-icon>
                                </template>
                            </v-btn>
                        </v-card>

                        <v-card class="card-main px-6 pt-10 px-sm-10 pt-sm-15 pb-5 my-8 animate-block">
                            <v-row justify="center">
                                <v-col cols="12" sm="4" align-self="center">
                                    <v-row>
                                        <v-col cols="auto" class="pa-1">
                                            <div class="div-hashtag">
                                                CPM
                                            </div>
                                        </v-col>
                                        <v-col cols="auto" class="pa-1">
                                            <div class="div-hashtag">
                                                Bookmaker
                                            </div>
                                        </v-col>
                                        <v-col cols="auto" class="pa-1">
                                            <div class="div-hashtag">
                                                Sports betting
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="10" sm="12">
                                            <v-img width="100%" class="mt-2" src="../../assets/img/home/p3.png"></v-img>
                                        </v-col>
                                    </v-row>
                                    <h4 class="card-title-text mt-3 mb-1">Batery sports traffic campaign</h4>
                                    <p class="card-suptitle">Austria, 2023</p>
                                </v-col>
                                <v-col cols="12" sm="5" offset-sm="1">
                                    <p class="card-content-text">
                                        We partnered with Batery to focus solely on sports
                                        traffic, launching highly
                                        targeted campaigns across multiple regions. To maximize engagement, we developed
                                        custom creatives
                                        tailored to the most popular sports in each country. This approach allowed us to
                                        effectively capture the
                                        attention of local sports fans, positioning Batery as their preferred bookmaker.
                                        Our strategy helped
                                        strengthen Batery’s presence in the sports betting world, delivering excellent
                                        results in both brand
                                        awareness and customer loyalty.</p>
                                </v-col>
                            </v-row>

                            <v-divider color="#FFF" class="mt-8 mb-3"></v-divider>

                            <v-btn href="https://batery.win/" target="_blank" class="card-btn" variant="text" block
                                size="large" color="#997B8D">
                                Open official website
                                <template v-slot:append>
                                    <v-icon class="mr-1">
                                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" width="32" height="32" rx="16" fill="#1C1016" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M13.1464 9.15771C13.3417 8.94743 13.6583 8.94743 13.8536 9.15771L19.8536 15.6193C20.0488 15.8295 20.0488 16.1705 19.8536 16.3808L13.8536 22.8423C13.6583 23.0526 13.3417 23.0526 13.1464 22.8423C12.9512 22.632 12.9512 22.2911 13.1464 22.0808L18.7929 16L13.1464 9.91921C12.9512 9.70893 12.9512 9.36799 13.1464 9.15771Z"
                                                fill="#D91C49" />
                                        </svg>
                                    </v-icon>
                                </template>
                            </v-btn>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: "PartnersAndCooperation",
    mounted() {
        this.createObserver();
    },
    methods: {
        createObserver() {
            const options = {
                root: null,
                threshold: 0.12,
            };
            const elements = document.querySelectorAll('.animate-block');
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                        observer.unobserve(entry.target);
                    }
                });
            }, options);
            elements.forEach(element => {
                observer.observe(element);
            });
        }
    }
}
</script>
<style scoped>
.animate-block {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animate-block.visible {
    opacity: 1;
    transform: translateY(0);
}

.div-hashtag {
    border: 1px solid #D91C49;
    background: #1C1016;
    border-radius: 42px;
    padding: 4px 20px;
    font-size: 0.95rem;
    font-weight: 400;
    letter-spacing: -0.03em;
    color: #C8C8F3;
}

.card-content-text {
    color: #C8C8F3;
    font-size: 1rem;
    letter-spacing: -0.02em;
}

.card-suptitle {
    color: #997B8D;
    font-size: 1rem;
    letter-spacing: -0.02em;
    font-weight: 400;
}

.card-title-text {
    letter-spacing: -0.02em;
    font-size: 1.6rem;
    color: #C8C8F3;
    font-weight: 400;
    text-wrap: balance;
}

.card-btn {
    text-transform: inherit;
    letter-spacing: -0.04em;
    font-size: 1.35rem;
}

.card-main {
    background: linear-gradient(180deg, #000000 0%, #14081A 42%, #09040C 95%);
    border: 1px solid #FFFFFF1F;
    border-radius: 30px;
}

.text-title {
    font-weight: 500;
    background: linear-gradient(90deg, #F7F8F8 41.23%, #C1285F 77.07%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    letter-spacing: -0.04em;
    font-size: 2.3rem;
}

.container-main {
    padding-top: 10vh;
    padding-bottom: 10vh;
}
</style>