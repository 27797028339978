<template>
  <v-footer class="d-flex flex-column footer-block pb-0 pt-14">
    <v-container class="py-0 my-10 px-4 px-md-15" fluid>
      <v-row justify="space-between">
        <v-col cols="12" sm="5" md="4" lg="3" class="pr-8 pl-1 pl-sm-7 px-md-0 pb-8 pb-md-0">
          <v-row justify="space-between" justify-sm="start" style="flex-wrap: nowrap;">
            <v-col order="2" order-sm="1" cols="auto" class="px-0">
              <v-btn icon variant="text" href="#top" size="large" height="auto" class="btn-svg-scroll-top" aria-label="btn-scroll-top">
                <svg width="60" viewBox="0 0 60 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M58.9286 77.3366V29.6634C58.9286 13.8658 45.9768 1.05941 30 1.05941C14.0232 1.05941 1.07143 13.8658 1.07143 29.6634V77.3366C1.07143 93.1342 14.0232 105.941 30 105.941C45.9768 105.941 58.9286 93.1342 58.9286 77.3366ZM30 0C13.4315 0 0 13.2807 0 29.6634V77.3366C0 93.7193 13.4315 107 30 107C46.5685 107 60 93.7193 60 77.3366V29.6634C60 13.2807 46.5685 0 30 0Z"
                    fill="url(#paint0_linear_244_388)" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M29.2424 39.5083C29.6608 39.0946 30.3392 39.0946 30.7576 39.5083L37.5761 46.2503C37.9946 46.6641 37.9946 47.3348 37.5761 47.7486C37.1577 48.1623 36.4793 48.1623 36.0609 47.7486L31.0714 42.8151V66.7426C31.0714 67.3277 30.5917 67.802 30 67.802C29.4083 67.802 28.9286 67.3277 28.9286 66.7426V42.8151L23.9391 47.7486C23.5207 48.1623 22.8423 48.1623 22.4239 47.7486C22.0054 47.3348 22.0054 46.6641 22.4239 46.2503L29.2424 39.5083Z"
                    fill="url(#paint1_linear_244_388)" />
                  <defs>
                    <linearGradient id="paint0_linear_244_388" x1="30" y1="0" x2="30" y2="107"
                      gradientUnits="userSpaceOnUse">
                      <stop stop-color="#D91C49" />
                      <stop offset="0.525" stop-color="#FFAED8" />
                      <stop offset="1" stop-color="#4D1F66" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_244_388" x1="30" y1="0" x2="30" y2="107"
                      gradientUnits="userSpaceOnUse">
                      <stop stop-color="#D91C49" />
                      <stop offset="0.525" stop-color="#FFAED8" />
                      <stop offset="1" stop-color="#4D1F66" />
                    </linearGradient>
                  </defs>
                </svg>
              </v-btn>
            </v-col>
            <v-col order="1" order-sm="2" cols="auto" offset-sm="1" align-self="stretch" class="px-0 px-sm-3"
              style="display: flex; flex-direction: column;">
              <v-row class="ma-0">
                <v-btn variant="text" size="small" href="#" target="_blank" class="btn-small">
                  Instagram
                </v-btn>
                <span class="span-line-verticaly">|</span>
                <v-btn variant="text" size="small" href="#" target="_blank" class="btn-small">
                  Telegram
                </v-btn>
                <span class="span-line-verticaly">|</span>
                <v-btn variant="text" size="small" href="#" target="_blank" class="btn-small">
                  Linkedin
                </v-btn>
              </v-row>
              <div>
                <v-row class="ma-0 px-3 mt-auto">
                  <p class="text-footer-small">
                    Working worldwide.
                  </p>
                </v-row>
                <v-row class="ma-0 px-3">
                  <p class="text-footer-small">
                    Based in Vienna
                  </p>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row justify="start" class="mt-12">
            <v-col cols="10" sm="8">
              <v-img width="100%" src="../assets/img/logo_big.png"></v-img>
            </v-col>
          </v-row>
          <v-row justify="start" class="my-8">
            <v-col cols="9">
              <a href="https://amark.pro/" target="_blank" aria-label="btn-developer-website">
                <v-img class="img_povered_by" min-height="20px" contain width="100%"
                  src="../assets/img/footer/footerAmark.png"></v-img>
              </a>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6" v-if="$vuetify.display.sm"></v-col>

        <v-col cols="6" sm="4" md="2" ffsetMd="1" class="px-0">
          <v-list width="100%" size="compact" class="list2">
            <v-list-item href="/#" value="home" class="list2-item">
              <v-list-item-title class="list2-item-title">
                Intro
              </v-list-item-title>
            </v-list-item>
            <v-list-item href="/#partners" value="real_estate" class="list2-item">
              <v-list-item-title class="list2-item-title">
                Partners
              </v-list-item-title>
            </v-list-item>
            <v-list-item href="/#about" value="about" class="list2-item">
              <v-list-item-title class="list2-item-title">
                About Us
              </v-list-item-title>
            </v-list-item>
            <v-list-item href="/#cpm" value="learn" class="list2-item">
              <v-list-item-title class="list2-item-title">
                Services
              </v-list-item-title>
            </v-list-item>
            <v-list-item href="/#process" value="partners" class="list2-item">
              <v-list-item-title class="list2-item-title">
                Our Process
              </v-list-item-title>
            </v-list-item>
            <v-list-item href="/#GetInTouch" value="contact" class="list2-item">
              <v-list-item-title class="list2-item-title">
                Get In Touch
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="6" sm="4" md="3" class="px-0">
          <v-list width="100%" size="compact" class="list3">
            <v-list-item class="list3-item" v-for="item in list4" :key="item.title" @click="OpenLegalPage(item)">
              <v-list-item-title class="list3-item-title">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>

  </v-footer>
</template>
<script>
export default {
  name: "FooterComponent",
  data: () => ({
    list4: [
      {
        title: "Allgemeine Geschäftsbedingungen",
        params: "allgemeine"
      },
      {
        title: "Datenschutz",
        params: "datenschutz"
      },
      {
        title: "Cookie",
        params: "cookie"
      },
      {
        title: "Impressum",
        params: "impressum"
      },
    ]
  }),
  methods: {
    OpenLegalPage(item) {
       this.$router.push({
         name: "legal",
         params: {
           lang: this.$vuetify.locale.current,
           name: item.params
         }
       })
    }
  }
};
</script>

<style scoped>
.list3 {
  background: transparent;
  padding: 0px;
}

.list3-item {
  min-height: 8px;
  width: auto;
  border-radius: 8px;
}

.list3-item:hover {
  background: rgba(255, 255, 255, 0.05);
}

.list3-item-title {
  color: #997B8D;
  letter-spacing: -0.01rem;
  font-size: 0.9rem;
}

.list2 {
  background: transparent;
  padding: 0px;
}

.list2-item {
  min-height: 44px;
  width: auto;
  border-radius: 8px;
}

.list2-item:hover {
  background: rgba(255, 255, 255, 0.05);
}

.list2-item-title {
  color: #C8C8F3;
  letter-spacing: -0.02rem;
  font-size: 1.4rem;
}

.span-line-verticaly {
  padding-top: 3px;
  color: #9A9A9B;
}

.text-footer-small {
  height: auto;
  color: #C8C8F3;
  letter-spacing: -0.01rem;
  font-size: 0.9rem;
}

.btn-small {
  color: #C8C8F3;
  text-transform: inherit;
  letter-spacing: -0.01rem;
}

.btn-svg-scroll-top {
  border-radius: 30px;
}

.btn-svg-scroll-top:hover {
  background: #F6F2E6;
}

.btn-svg-scroll-top:hover .svg-path {
  fill: #06080A;
}

.footer-block {
  width: 100%;
  background: linear-gradient(180deg, #000000 0%, #14081A 42%, #09040C 95%);
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 600px) {

  .list2-item {
    min-height: 32px;
  }

  .list2-item-title {
    letter-spacing: -0.015rem;
    font-size: 1.2rem;
  }

}
</style>