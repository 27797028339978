<template>
    <v-container fluid class="container-main">
        <v-row justify="center">
            <v-col cols="12" md="10">
                <v-row justify="space-between">
                    <v-col cols="12">
                        <v-card class="card-main animate-block">
                            <v-row justify="center">
                                <v-col cols="12" sm="8">
                                    <h3 class="card-title">Book a call with us Today</h3>
                                    <p class="card-suptitle mt-3">Start iGaming marketing actions today! <br v-if="$vuetify.display.smAndUp" />
                                        Book a free call with WORK-O Production to discuss <br v-if="$vuetify.display.smAndUp" />
                                        the most suitable strategy for your business!
                                    </p>
                                </v-col>
                                <v-col cols="10" sm="4" align-self="center">
                                    <v-btn href="https://calendly.com/office-work-o/15min" target="_blank" color="#C8C8F3" class="btn_request" block size="x-large">
                                        Book a call
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: "BookCall",
    mounted() {
        this.createObserver();
    },
    methods: {
        createObserver() {
            const options = {
                root: null,
                threshold: 0.12,
            };
            const elements = document.querySelectorAll('.animate-block');
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                        observer.unobserve(entry.target);
                    }
                });
            }, options);
            elements.forEach(element => {
                observer.observe(element);
            });
        }
    }
}
</script>
<style scoped>
.animate-block {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animate-block.visible {
    opacity: 1;
    transform: translateY(0);
}

.card-main {
    background: linear-gradient(94.55deg, #D91C49 -15.09%, #09040C 63.02%);
    border-radius: 30px;
    padding: 38px;
}

.card-title {
    color: #C8C8F3;
    font-weight: 500;
    letter-spacing: -0.2rem;
    font-size: 3.5rem;
}

.card-suptitle {
    color: #C8C8F3;
    font-weight: 500;
    letter-spacing: -0.03rem;
    font-size: 1.05rem;
}

.btn_request {
    text-transform: inherit;
    border-radius: 20px;
    letter-spacing: -0.1px;
    color: #2B2B2A;
    font-weight: 500;
}

.container-main {
    padding-top: 10vh;
    padding-bottom: 10vh;
}

@media (max-device-width: 768px) {

    .card-main {
        padding: 30px;
    }


    .card-suptitle {
        font-size: 1rem;
        text-align: center;

    }

    .card-title {
        text-align: center;
        font-size: 2.2rem;
    }

}
</style>