<template>
    <v-container fluid class="container-main" style="position: relative;">
        <v-row justify="center" style="z-index: 1; position: relative;" class="px-4 px-md-10 px-lg-4" v-show="textAnim">
            <v-col cols="12" sm="7" md="6" lg="5" alignSelf="center">

                <div class="text-left text-subtitle animate-block">

                    <svg width="79" height="16" viewBox="0 0 79 16" class="mr-2" style="vertical-align: middle;"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.2"
                            d="M10.7884 9.15438C10.7242 9.21082 10.6764 9.2836 10.6502 9.365C10.624 9.44639 10.6203 9.53337 10.6396 9.61669L11.4318 13.0468C11.4527 13.1364 11.4468 13.2301 11.4148 13.3164C11.3829 13.4026 11.3262 13.4776 11.2519 13.5319C11.1776 13.5862 11.089 13.6174 10.9971 13.6217C10.9052 13.626 10.8141 13.6031 10.7351 13.5559L7.74096 11.7395C7.66821 11.6953 7.5847 11.6719 7.49955 11.6719C7.41441 11.6719 7.3309 11.6953 7.25815 11.7395L4.26401 13.5559C4.18501 13.6031 4.09388 13.626 4.00198 13.6217C3.91007 13.6174 3.82146 13.5862 3.74719 13.5319C3.67291 13.4776 3.61625 13.4026 3.58427 13.3164C3.55228 13.2301 3.54639 13.1364 3.56733 13.0468L4.35952 9.61669C4.37877 9.53337 4.3751 9.44639 4.3489 9.365C4.32269 9.2836 4.27493 9.21082 4.21069 9.15438L1.56752 6.84872C1.49686 6.78866 1.4456 6.70899 1.42023 6.61978C1.39486 6.53058 1.39651 6.43586 1.42499 6.3476C1.45347 6.25934 1.50749 6.18152 1.58021 6.12397C1.65294 6.06642 1.7411 6.03173 1.83354 6.0243L5.31752 5.72313C5.40252 5.71556 5.48384 5.68493 5.55272 5.63454C5.62159 5.58415 5.6754 5.51592 5.70834 5.43719L7.06948 2.19344C7.10583 2.10958 7.16591 2.03817 7.24233 1.98802C7.31874 1.93786 7.40815 1.91113 7.49955 1.91113C7.59096 1.91113 7.68037 1.93786 7.75678 1.98802C7.8332 2.03817 7.89328 2.10958 7.92963 2.19344L9.29077 5.43719C9.32371 5.51592 9.37752 5.58415 9.44639 5.63454C9.51526 5.68493 9.59659 5.71556 9.68159 5.72313L13.1656 6.0243C13.258 6.03173 13.3462 6.06642 13.4189 6.12397C13.4916 6.18152 13.5456 6.25934 13.5741 6.3476C13.6026 6.43586 13.6043 6.53058 13.5789 6.61978C13.5535 6.70899 13.5023 6.78866 13.4316 6.84872L10.7884 9.15438Z"
                            fill="#C8C8F3" />
                        <path
                            d="M14.0156 6.20047C13.9589 6.0255 13.8519 5.8711 13.708 5.75649C13.5641 5.64188 13.3897 5.57214 13.2065 5.55594L9.72658 5.25594L8.36251 2.01219C8.29149 1.84198 8.1717 1.69659 8.01823 1.59433C7.86475 1.49207 7.68444 1.4375 7.50001 1.4375C7.31559 1.4375 7.13528 1.49207 6.9818 1.59433C6.82832 1.69659 6.70853 1.84198 6.63751 2.01219L5.27755 5.25594L1.79357 5.5577C1.6096 5.57315 1.4343 5.64258 1.28964 5.75728C1.14497 5.87197 1.03739 6.02682 0.980387 6.20241C0.92338 6.378 0.919484 6.56651 0.969186 6.7443C1.01889 6.9221 1.11998 7.08126 1.25978 7.20184L3.90294 9.5116L3.11076 12.9417C3.06887 13.1212 3.08082 13.309 3.14512 13.4818C3.20942 13.6545 3.32321 13.8045 3.47228 13.9129C3.62134 14.0213 3.79905 14.0834 3.9832 14.0914C4.16735 14.0993 4.34976 14.0528 4.50763 13.9577L7.49591 12.1413L10.4906 13.9577C10.6485 14.0528 10.8309 14.0993 11.0151 14.0914C11.1992 14.0834 11.3769 14.0213 11.526 13.9129C11.6751 13.8045 11.7888 13.6545 11.8531 13.4818C11.9174 13.309 11.9294 13.1212 11.8875 12.9417L11.0959 9.50809L13.7385 7.20184C13.8783 7.08085 13.9792 6.92123 14.0285 6.74306C14.0778 6.56489 14.0733 6.37611 14.0156 6.20047ZM13.1238 6.49344L10.4813 8.79969C10.3527 8.91156 10.257 9.05639 10.2045 9.21858C10.1521 9.38077 10.1448 9.5542 10.1836 9.72019L10.9776 13.1561L7.98517 11.3397C7.83918 11.2508 7.67153 11.2038 7.5006 11.2038C7.32966 11.2038 7.16202 11.2508 7.01603 11.3397L4.02775 13.1561L4.81642 9.72254C4.85518 9.55654 4.84795 9.38312 4.7955 9.22092C4.74305 9.05873 4.64738 8.91391 4.51876 8.80203L1.87501 6.49695C1.8748 6.4952 1.8748 6.49343 1.87501 6.49168L5.35783 6.19051C5.52787 6.17552 5.69059 6.11439 5.82844 6.01371C5.96629 5.91304 6.07403 5.77663 6.14005 5.61922L7.50001 2.37957L8.85939 5.61922C8.92541 5.77663 9.03315 5.91304 9.171 6.01371C9.30885 6.11439 9.47157 6.17552 9.64161 6.19051L13.125 6.49168C13.125 6.49168 13.125 6.4952 13.125 6.49578L13.1238 6.49344Z"
                            fill="#C8C8F3" />
                        <path opacity="0.2"
                            d="M26.7884 9.15438C26.7242 9.21082 26.6764 9.2836 26.6502 9.365C26.624 9.44639 26.6203 9.53337 26.6396 9.61669L27.4318 13.0468C27.4527 13.1364 27.4468 13.2301 27.4148 13.3164C27.3829 13.4026 27.3262 13.4776 27.2519 13.5319C27.1776 13.5862 27.089 13.6174 26.9971 13.6217C26.9052 13.626 26.8141 13.6031 26.7351 13.5559L23.741 11.7395C23.6682 11.6953 23.5847 11.6719 23.4996 11.6719C23.4144 11.6719 23.3309 11.6953 23.2581 11.7395L20.264 13.5559C20.185 13.6031 20.0939 13.626 20.002 13.6217C19.9101 13.6174 19.8215 13.5862 19.7472 13.5319C19.6729 13.4776 19.6162 13.4026 19.5843 13.3164C19.5523 13.2301 19.5464 13.1364 19.5673 13.0468L20.3595 9.61669C20.3788 9.53337 20.3751 9.44639 20.3489 9.365C20.3227 9.2836 20.2749 9.21082 20.2107 9.15438L17.5675 6.84872C17.4969 6.78866 17.4456 6.70899 17.4202 6.61978C17.3949 6.53058 17.3965 6.43586 17.425 6.3476C17.4535 6.25934 17.5075 6.18152 17.5802 6.12397C17.6529 6.06642 17.7411 6.03173 17.8335 6.0243L21.3175 5.72313C21.4025 5.71556 21.4838 5.68493 21.5527 5.63454C21.6216 5.58415 21.6754 5.51592 21.7083 5.43719L23.0695 2.19344C23.1058 2.10958 23.1659 2.03817 23.2423 1.98802C23.3187 1.93786 23.4081 1.91113 23.4996 1.91113C23.591 1.91113 23.6804 1.93786 23.7568 1.98802C23.8332 2.03817 23.8933 2.10958 23.9296 2.19344L25.2908 5.43719C25.3237 5.51592 25.3775 5.58415 25.4464 5.63454C25.5153 5.68493 25.5966 5.71556 25.6816 5.72313L29.1656 6.0243C29.258 6.03173 29.3462 6.06642 29.4189 6.12397C29.4916 6.18152 29.5456 6.25934 29.5741 6.3476C29.6026 6.43586 29.6043 6.53058 29.5789 6.61978C29.5535 6.70899 29.5023 6.78866 29.4316 6.84872L26.7884 9.15438Z"
                            fill="#C8C8F3" />
                        <path
                            d="M30.0156 6.20047C29.9589 6.0255 29.8519 5.8711 29.708 5.75649C29.5641 5.64188 29.3897 5.57214 29.2065 5.55594L25.7266 5.25594L24.3625 2.01219C24.2915 1.84198 24.1717 1.69659 24.0182 1.59433C23.8647 1.49207 23.6844 1.4375 23.5 1.4375C23.3156 1.4375 23.1353 1.49207 22.9818 1.59433C22.8283 1.69659 22.7085 1.84198 22.6375 2.01219L21.2776 5.25594L17.7936 5.5577C17.6096 5.57315 17.4343 5.64258 17.2896 5.75728C17.145 5.87197 17.0374 6.02682 16.9804 6.20241C16.9234 6.378 16.9195 6.56651 16.9692 6.7443C17.0189 6.9221 17.12 7.08126 17.2598 7.20184L19.9029 9.5116L19.1108 12.9417C19.0689 13.1212 19.0808 13.309 19.1451 13.4818C19.2094 13.6545 19.3232 13.8045 19.4723 13.9129C19.6213 14.0213 19.7991 14.0834 19.9832 14.0914C20.1673 14.0993 20.3498 14.0528 20.5076 13.9577L23.4959 12.1413L26.4906 13.9577C26.6485 14.0528 26.8309 14.0993 27.0151 14.0914C27.1992 14.0834 27.3769 14.0213 27.526 13.9129C27.6751 13.8045 27.7888 13.6545 27.8531 13.4818C27.9174 13.309 27.9294 13.1212 27.8875 12.9417L27.0959 9.50809L29.7385 7.20184C29.8783 7.08085 29.9792 6.92123 30.0285 6.74306C30.0778 6.56489 30.0733 6.37611 30.0156 6.20047ZM29.1238 6.49344L26.4813 8.79969C26.3527 8.91156 26.257 9.05639 26.2045 9.21858C26.1521 9.38077 26.1448 9.5542 26.1836 9.72019L26.9776 13.1561L23.9852 11.3397C23.8392 11.2508 23.6715 11.2038 23.5006 11.2038C23.3297 11.2038 23.162 11.2508 23.016 11.3397L20.0277 13.1561L20.8164 9.72254C20.8552 9.55654 20.8479 9.38312 20.7955 9.22092C20.7431 9.05873 20.6474 8.91391 20.5188 8.80203L17.875 6.49695C17.8748 6.4952 17.8748 6.49343 17.875 6.49168L21.3578 6.19051C21.5279 6.17552 21.6906 6.11439 21.8284 6.01371C21.9663 5.91304 22.074 5.77663 22.1401 5.61922L23.5 2.37957L24.8594 5.61922C24.9254 5.77663 25.0332 5.91304 25.171 6.01371C25.3089 6.11439 25.4716 6.17552 25.6416 6.19051L29.125 6.49168C29.125 6.49168 29.125 6.4952 29.125 6.49578L29.1238 6.49344Z"
                            fill="#C8C8F3" />
                        <path opacity="0.2"
                            d="M42.7884 9.15438C42.7242 9.21082 42.6764 9.2836 42.6502 9.365C42.624 9.44639 42.6203 9.53337 42.6396 9.61669L43.4318 13.0468C43.4527 13.1364 43.4468 13.2301 43.4148 13.3164C43.3829 13.4026 43.3262 13.4776 43.2519 13.5319C43.1776 13.5862 43.089 13.6174 42.9971 13.6217C42.9052 13.626 42.8141 13.6031 42.7351 13.5559L39.741 11.7395C39.6682 11.6953 39.5847 11.6719 39.4996 11.6719C39.4144 11.6719 39.3309 11.6953 39.2581 11.7395L36.264 13.5559C36.185 13.6031 36.0939 13.626 36.002 13.6217C35.9101 13.6174 35.8215 13.5862 35.7472 13.5319C35.6729 13.4776 35.6162 13.4026 35.5843 13.3164C35.5523 13.2301 35.5464 13.1364 35.5673 13.0468L36.3595 9.61669C36.3788 9.53337 36.3751 9.44639 36.3489 9.365C36.3227 9.2836 36.2749 9.21082 36.2107 9.15438L33.5675 6.84872C33.4969 6.78866 33.4456 6.70899 33.4202 6.61978C33.3949 6.53058 33.3965 6.43586 33.425 6.3476C33.4535 6.25934 33.5075 6.18152 33.5802 6.12397C33.6529 6.06642 33.7411 6.03173 33.8335 6.0243L37.3175 5.72313C37.4025 5.71556 37.4838 5.68493 37.5527 5.63454C37.6216 5.58415 37.6754 5.51592 37.7083 5.43719L39.0695 2.19344C39.1058 2.10958 39.1659 2.03817 39.2423 1.98802C39.3187 1.93786 39.4081 1.91113 39.4996 1.91113C39.591 1.91113 39.6804 1.93786 39.7568 1.98802C39.8332 2.03817 39.8933 2.10958 39.9296 2.19344L41.2908 5.43719C41.3237 5.51592 41.3775 5.58415 41.4464 5.63454C41.5153 5.68493 41.5966 5.71556 41.6816 5.72313L45.1656 6.0243C45.258 6.03173 45.3462 6.06642 45.4189 6.12397C45.4916 6.18152 45.5456 6.25934 45.5741 6.3476C45.6026 6.43586 45.6043 6.53058 45.5789 6.61978C45.5535 6.70899 45.5023 6.78866 45.4316 6.84872L42.7884 9.15438Z"
                            fill="#C8C8F3" />
                        <path
                            d="M46.0156 6.20047C45.9589 6.0255 45.8519 5.8711 45.708 5.75649C45.5641 5.64188 45.3897 5.57214 45.2065 5.55594L41.7266 5.25594L40.3625 2.01219C40.2915 1.84198 40.1717 1.69659 40.0182 1.59433C39.8647 1.49207 39.6844 1.4375 39.5 1.4375C39.3156 1.4375 39.1353 1.49207 38.9818 1.59433C38.8283 1.69659 38.7085 1.84198 38.6375 2.01219L37.2776 5.25594L33.7936 5.5577C33.6096 5.57315 33.4343 5.64258 33.2896 5.75728C33.145 5.87197 33.0374 6.02682 32.9804 6.20241C32.9234 6.378 32.9195 6.56651 32.9692 6.7443C33.0189 6.9221 33.12 7.08126 33.2598 7.20184L35.9029 9.5116L35.1108 12.9417C35.0689 13.1212 35.0808 13.309 35.1451 13.4818C35.2094 13.6545 35.3232 13.8045 35.4723 13.9129C35.6213 14.0213 35.7991 14.0834 35.9832 14.0914C36.1673 14.0993 36.3498 14.0528 36.5076 13.9577L39.4959 12.1413L42.4906 13.9577C42.6485 14.0528 42.8309 14.0993 43.0151 14.0914C43.1992 14.0834 43.3769 14.0213 43.526 13.9129C43.6751 13.8045 43.7888 13.6545 43.8531 13.4818C43.9174 13.309 43.9294 13.1212 43.8875 12.9417L43.0959 9.50809L45.7385 7.20184C45.8783 7.08085 45.9792 6.92123 46.0285 6.74306C46.0778 6.56489 46.0733 6.37611 46.0156 6.20047ZM45.1238 6.49344L42.4813 8.79969C42.3527 8.91156 42.257 9.05639 42.2045 9.21858C42.1521 9.38077 42.1448 9.5542 42.1836 9.72019L42.9776 13.1561L39.9852 11.3397C39.8392 11.2508 39.6715 11.2038 39.5006 11.2038C39.3297 11.2038 39.162 11.2508 39.016 11.3397L36.0277 13.1561L36.8164 9.72254C36.8552 9.55654 36.8479 9.38312 36.7955 9.22092C36.7431 9.05873 36.6474 8.91391 36.5188 8.80203L33.875 6.49695C33.8748 6.4952 33.8748 6.49343 33.875 6.49168L37.3578 6.19051C37.5279 6.17552 37.6906 6.11439 37.8284 6.01371C37.9663 5.91304 38.074 5.77663 38.1401 5.61922L39.5 2.37957L40.8594 5.61922C40.9254 5.77663 41.0332 5.91304 41.171 6.01371C41.3089 6.11439 41.4716 6.17552 41.6416 6.19051L45.125 6.49168C45.125 6.49168 45.125 6.4952 45.125 6.49578L45.1238 6.49344Z"
                            fill="#C8C8F3" />
                        <path opacity="0.2"
                            d="M58.7884 9.15438C58.7242 9.21082 58.6764 9.2836 58.6502 9.365C58.624 9.44639 58.6203 9.53337 58.6396 9.61669L59.4318 13.0468C59.4527 13.1364 59.4468 13.2301 59.4148 13.3164C59.3829 13.4026 59.3262 13.4776 59.2519 13.5319C59.1776 13.5862 59.089 13.6174 58.9971 13.6217C58.9052 13.626 58.8141 13.6031 58.7351 13.5559L55.741 11.7395C55.6682 11.6953 55.5847 11.6719 55.4996 11.6719C55.4144 11.6719 55.3309 11.6953 55.2581 11.7395L52.264 13.5559C52.185 13.6031 52.0939 13.626 52.002 13.6217C51.9101 13.6174 51.8215 13.5862 51.7472 13.5319C51.6729 13.4776 51.6162 13.4026 51.5843 13.3164C51.5523 13.2301 51.5464 13.1364 51.5673 13.0468L52.3595 9.61669C52.3788 9.53337 52.3751 9.44639 52.3489 9.365C52.3227 9.2836 52.2749 9.21082 52.2107 9.15438L49.5675 6.84872C49.4969 6.78866 49.4456 6.70899 49.4202 6.61978C49.3949 6.53058 49.3965 6.43586 49.425 6.3476C49.4535 6.25934 49.5075 6.18152 49.5802 6.12397C49.6529 6.06642 49.7411 6.03173 49.8335 6.0243L53.3175 5.72313C53.4025 5.71556 53.4838 5.68493 53.5527 5.63454C53.6216 5.58415 53.6754 5.51592 53.7083 5.43719L55.0695 2.19344C55.1058 2.10958 55.1659 2.03817 55.2423 1.98802C55.3187 1.93786 55.4081 1.91113 55.4996 1.91113C55.591 1.91113 55.6804 1.93786 55.7568 1.98802C55.8332 2.03817 55.8933 2.10958 55.9296 2.19344L57.2908 5.43719C57.3237 5.51592 57.3775 5.58415 57.4464 5.63454C57.5153 5.68493 57.5966 5.71556 57.6816 5.72313L61.1656 6.0243C61.258 6.03173 61.3462 6.06642 61.4189 6.12397C61.4916 6.18152 61.5456 6.25934 61.5741 6.3476C61.6026 6.43586 61.6043 6.53058 61.5789 6.61978C61.5535 6.70899 61.5023 6.78866 61.4316 6.84872L58.7884 9.15438Z"
                            fill="#C8C8F3" />
                        <path
                            d="M62.0156 6.20047C61.9589 6.0255 61.8519 5.8711 61.708 5.75649C61.5641 5.64188 61.3897 5.57214 61.2065 5.55594L57.7266 5.25594L56.3625 2.01219C56.2915 1.84198 56.1717 1.69659 56.0182 1.59433C55.8647 1.49207 55.6844 1.4375 55.5 1.4375C55.3156 1.4375 55.1353 1.49207 54.9818 1.59433C54.8283 1.69659 54.7085 1.84198 54.6375 2.01219L53.2776 5.25594L49.7936 5.5577C49.6096 5.57315 49.4343 5.64258 49.2896 5.75728C49.145 5.87197 49.0374 6.02682 48.9804 6.20241C48.9234 6.378 48.9195 6.56651 48.9692 6.7443C49.0189 6.9221 49.12 7.08126 49.2598 7.20184L51.9029 9.5116L51.1108 12.9417C51.0689 13.1212 51.0808 13.309 51.1451 13.4818C51.2094 13.6545 51.3232 13.8045 51.4723 13.9129C51.6213 14.0213 51.7991 14.0834 51.9832 14.0914C52.1673 14.0993 52.3498 14.0528 52.5076 13.9577L55.4959 12.1413L58.4906 13.9577C58.6485 14.0528 58.8309 14.0993 59.0151 14.0914C59.1992 14.0834 59.3769 14.0213 59.526 13.9129C59.6751 13.8045 59.7888 13.6545 59.8531 13.4818C59.9174 13.309 59.9294 13.1212 59.8875 12.9417L59.0959 9.50809L61.7385 7.20184C61.8783 7.08085 61.9792 6.92123 62.0285 6.74306C62.0778 6.56489 62.0733 6.37611 62.0156 6.20047ZM61.1238 6.49344L58.4813 8.79969C58.3527 8.91156 58.257 9.05639 58.2045 9.21858C58.1521 9.38077 58.1448 9.5542 58.1836 9.72019L58.9776 13.1561L55.9852 11.3397C55.8392 11.2508 55.6715 11.2038 55.5006 11.2038C55.3297 11.2038 55.162 11.2508 55.016 11.3397L52.0277 13.1561L52.8164 9.72254C52.8552 9.55654 52.8479 9.38312 52.7955 9.22092C52.7431 9.05873 52.6474 8.91391 52.5188 8.80203L49.875 6.49695C49.8748 6.4952 49.8748 6.49343 49.875 6.49168L53.3578 6.19051C53.5279 6.17552 53.6906 6.11439 53.8284 6.01371C53.9663 5.91304 54.074 5.77663 54.1401 5.61922L55.5 2.37957L56.8594 5.61922C56.9254 5.77663 57.0332 5.91304 57.171 6.01371C57.3089 6.11439 57.4716 6.17552 57.6416 6.19051L61.125 6.49168C61.125 6.49168 61.125 6.4952 61.125 6.49578L61.1238 6.49344Z"
                            fill="#C8C8F3" />
                        <path opacity="0.2"
                            d="M74.7884 9.15438C74.7242 9.21082 74.6764 9.2836 74.6502 9.365C74.624 9.44639 74.6203 9.53337 74.6396 9.61669L75.4318 13.0468C75.4527 13.1364 75.4468 13.2301 75.4148 13.3164C75.3829 13.4026 75.3262 13.4776 75.2519 13.5319C75.1776 13.5862 75.089 13.6174 74.9971 13.6217C74.9052 13.626 74.8141 13.6031 74.7351 13.5559L71.741 11.7395C71.6682 11.6953 71.5847 11.6719 71.4996 11.6719C71.4144 11.6719 71.3309 11.6953 71.2581 11.7395L68.264 13.5559C68.185 13.6031 68.0939 13.626 68.002 13.6217C67.9101 13.6174 67.8215 13.5862 67.7472 13.5319C67.6729 13.4776 67.6162 13.4026 67.5843 13.3164C67.5523 13.2301 67.5464 13.1364 67.5673 13.0468L68.3595 9.61669C68.3788 9.53337 68.3751 9.44639 68.3489 9.365C68.3227 9.2836 68.2749 9.21082 68.2107 9.15438L65.5675 6.84872C65.4969 6.78866 65.4456 6.70899 65.4202 6.61978C65.3949 6.53058 65.3965 6.43586 65.425 6.3476C65.4535 6.25934 65.5075 6.18152 65.5802 6.12397C65.6529 6.06642 65.7411 6.03173 65.8335 6.0243L69.3175 5.72313C69.4025 5.71556 69.4838 5.68493 69.5527 5.63454C69.6216 5.58415 69.6754 5.51592 69.7083 5.43719L71.0695 2.19344C71.1058 2.10958 71.1659 2.03817 71.2423 1.98802C71.3187 1.93786 71.4081 1.91113 71.4996 1.91113C71.591 1.91113 71.6804 1.93786 71.7568 1.98802C71.8332 2.03817 71.8933 2.10958 71.9296 2.19344L73.2908 5.43719C73.3237 5.51592 73.3775 5.58415 73.4464 5.63454C73.5153 5.68493 73.5966 5.71556 73.6816 5.72313L77.1656 6.0243C77.258 6.03173 77.3462 6.06642 77.4189 6.12397C77.4916 6.18152 77.5456 6.25934 77.5741 6.3476C77.6026 6.43586 77.6043 6.53058 77.5789 6.61978C77.5535 6.70899 77.5023 6.78866 77.4316 6.84872L74.7884 9.15438Z"
                            fill="#C8C8F3" />
                        <path
                            d="M78.0156 6.20047C77.9589 6.0255 77.8519 5.8711 77.708 5.75649C77.5641 5.64188 77.3897 5.57214 77.2065 5.55594L73.7266 5.25594L72.3625 2.01219C72.2915 1.84198 72.1717 1.69659 72.0182 1.59433C71.8647 1.49207 71.6844 1.4375 71.5 1.4375C71.3156 1.4375 71.1353 1.49207 70.9818 1.59433C70.8283 1.69659 70.7085 1.84198 70.6375 2.01219L69.2776 5.25594L65.7936 5.5577C65.6096 5.57315 65.4343 5.64258 65.2896 5.75728C65.145 5.87197 65.0374 6.02682 64.9804 6.20241C64.9234 6.378 64.9195 6.56651 64.9692 6.7443C65.0189 6.9221 65.12 7.08126 65.2598 7.20184L67.9029 9.5116L67.1108 12.9417C67.0689 13.1212 67.0808 13.309 67.1451 13.4818C67.2094 13.6545 67.3232 13.8045 67.4723 13.9129C67.6213 14.0213 67.7991 14.0834 67.9832 14.0914C68.1673 14.0993 68.3498 14.0528 68.5076 13.9577L71.4959 12.1413L74.4906 13.9577C74.6485 14.0528 74.8309 14.0993 75.0151 14.0914C75.1992 14.0834 75.3769 14.0213 75.526 13.9129C75.6751 13.8045 75.7888 13.6545 75.8531 13.4818C75.9174 13.309 75.9294 13.1212 75.8875 12.9417L75.0959 9.50809L77.7385 7.20184C77.8783 7.08085 77.9792 6.92123 78.0285 6.74306C78.0778 6.56489 78.0733 6.37611 78.0156 6.20047ZM77.1238 6.49344L74.4813 8.79969C74.3527 8.91156 74.257 9.05639 74.2045 9.21858C74.1521 9.38077 74.1448 9.5542 74.1836 9.72019L74.9776 13.1561L71.9852 11.3397C71.8392 11.2508 71.6715 11.2038 71.5006 11.2038C71.3297 11.2038 71.162 11.2508 71.016 11.3397L68.0277 13.1561L68.8164 9.72254C68.8552 9.55654 68.8479 9.38312 68.7955 9.22092C68.7431 9.05873 68.6474 8.91391 68.5188 8.80203L65.875 6.49695C65.8748 6.4952 65.8748 6.49343 65.875 6.49168L69.3578 6.19051C69.5279 6.17552 69.6906 6.11439 69.8284 6.01371C69.9663 5.91304 70.074 5.77663 70.1401 5.61922L71.5 2.37957L72.8594 5.61922C72.9254 5.77663 73.0332 5.91304 73.171 6.01371C73.3089 6.11439 73.4716 6.17552 73.6416 6.19051L77.125 6.49168C77.125 6.49168 77.125 6.4952 77.125 6.49578L77.1238 6.49344Z"
                            fill="#C8C8F3" />
                    </svg>

                    <span>
                        Proven by iGaming leaders
                    </span>
                </div>

                <h1 class="text-left text-title my-4 animate-block2">
                    Your All-in-One Marketing Agency in the iGaming Industry
                </h1>

                <h3 class="text-left text-suptitle2 animate-block3">
                    Driving growth through precision marketing for casinos and sportsbook operators worldwide
                </h3>

                <v-row justify="start" class="mt-4 animate-block4">
                    <v-col cols="6" sm="5" md="5">
                        <v-btn href="https://calendly.com/office-work-o/15min" target="_blank" color="#C8C8F3"
                            class="btn_request" block size="x-large">
                            Book a call
                        </v-btn>
                    </v-col>
                    <v-col cols="6" sm="5" md="5">
                        <v-btn href="#cpm" variant="tonal" color="#fff" class="btn_explore" block size="x-large">
                            Learn more
                            <template v-slot:append>
                                <v-icon color="#fff">mdi-chevron-right</v-icon>
                            </template>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" sm="5" md="6" lg="5" alignSelf="center" class="animate-block-svg">
                <v-img width="100%" aspectRatio="1" alt="games illustration" contain max-height="100%"
                    src="../../assets/img/home/1.png">
                </v-img>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: "firstBlock",
    data: () => ({
        textAnim: false
    }),
    mounted() {
        this.createObserver();

        if (document.readyState == "complete") {
            this.textAnim = true;
        } else {
            document.addEventListener(
                "PreloaderFinish",
                () => {
                    this.textAnim = true;
                },
                false
            );
        }
    },
    methods: {
        createObserver() {
            const options = {
                root: null,
                threshold: 0.12,
            };
            const elements = document.querySelectorAll('.animate-block, .animate-block2, .animate-block3, .animate-block4, .animate-block-svg');
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                        observer.unobserve(entry.target);
                    }
                });
            }, options);
            elements.forEach(element => {
                observer.observe(element);
            });
        }
    }
}
</script>
<style scoped>
.animate-block {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animate-block.visible {
    opacity: 1;
    transform: translateY(0);
}

.animate-block2 {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animate-block2.visible {
    opacity: 1;
    transition-delay: 0.3s;
    transform: translateY(0);
}

.animate-block3 {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animate-block3.visible {
    opacity: 1;
    transition-delay: 0.6s;
    transform: translateY(0);
}

.animate-block4 {
    opacity: 0;
    transform: translateY(0px);
    transition: opacity 1.2s ease-out, transform 0.5s ease-out;
}

.animate-block4.visible {
    opacity: 1;
    transition-delay: 0.9s;
    transform: translateY(0);
}

.animate-block-svg {
    opacity: 0;
    transform: translateX(15px);
    transition: opacity 1.2s ease-out, transform 1.2s ease-out;
}

.animate-block-svg.visible {
    opacity: 1;
    transition-delay: 1.0s;
    transform: translateX(0);
}

.svg_subimage {
    height: 100%;
}

.btn_explore {
    text-transform: inherit;
    border-radius: 20px;
    letter-spacing: -0.1px;
    font-weight: 500;
}

.btn_request {
    text-transform: inherit;
    border-radius: 20px;
    letter-spacing: -0.1px;
    color: #2B2B2A;
    font-weight: 500;
}

.text-suptitle2 {
    color: #997B8D;
    font-size: 1.35rem;
    letter-spacing: -0.5px;
    font-weight: 500;
}

.text-suptitle {
    color: #FFFFFF;
    font-size: 1.6rem;
    letter-spacing: -0.5px;
    font-weight: 500;
}

.text-subtitle {
    background: rgba(47, 47, 47, 0.431);
    border-radius: 40px;
    padding: 7px 12px;
    color: #DEE4EE;
    letter-spacing: -0.5px;
    font-size: 1rem;
    width: fit-content;
    line-height: 0px;
}

.text-title {
    font-weight: 500;
    background: linear-gradient(90deg, #DEE4EE 0%, #D91C49 57.5%, #DEE4EE 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    letter-spacing: -0.04em;
    font-size: 2.3rem;
}

.container-main {
    padding-top: 20vh;
    padding-bottom: 15vh;
    min-height: 100vh;
    width: 100%;
    max-width: 100% !important;
    padding-left: 0px;
    padding-right: 0px;
}

@media (min-device-width: 220px) and (max-device-width: 768px) {
    .container-main {
        padding-top: 15vh;
        padding-bottom: 5vh;
    }
}

/* .blur-1 {
    width: 800px;
    height: 400px;
    position: absolute;
    transform: rotate(45deg);
    top: 0%;
    left: -30%;
    background: linear-gradient(rgba(217, 28, 72, 0.25), rgba(79, 26, 102, 0.25));
    -webkit-backdrop-filter: blur(200px);
    filter: blur(200px);
    border-radius: 100%;
    will-change: transform;
    z-index: 0;
    animation-name: blur-1-anim;
    animation-duration: 3s;
    animation-direction: alternate;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes blur-1-anim {
    from {
        filter: blur(180px);
        background: rgba(79, 26, 102, 0.25);

    }

    to {
        filter: blur(250px);
        background: rgba(217, 28, 72, 0.3);
    }
}

.blur-2 {
    width: 300px;
    height: 300px;
    position: absolute;
    transform: rotate(45deg);
    top: 20%;
    left: 55%;
    background: rgba(217, 28, 72, 0.5);
    -webkit-backdrop-filter: blur(200px);
    filter: blur(200px);
    border-radius: 100%;
    will-change: transform;
    z-index: 0;
}

.blur-3 {
    width: 300px;
    height: 300px;
    position: absolute;
    transform: rotate(45deg);
    top: 60%;
    left: 65%;
    background: rgba(170, 37, 227, 0.5);
    -webkit-backdrop-filter: blur(200px);
    filter: blur(200px);
    border-radius: 100%;
    will-change: transform;
    z-index: 0;
} */
</style>